import React from 'react';
import TimberCalc from '../../component/commodities/timber/TimberCalc/TimberCalc';
import GetStarted from '../../component/common/GetStarted/GetStarted';
import TimberIndex from "../../component/commodities/timber/TimberIndex/TimberIndex"
import LightBlueBgBanner from '../../component/common/LightBlueBgBanner/LightBlueBgBanner'

const TimberScreen = () => {

    const getStartedData = {
        title:"Get Started with our Portal",
        subTitle:"Signup on our portal and increase your business",
        btn:"Join Now",
        link: "https://login.portlinksindia.com/"
    }

    return (
        <div>
            {/* <LightBlueBgBanner headline={"Timber"}/> */}
            <TimberIndex />
            <TimberCalc />
            <GetStarted getStartedData={getStartedData} />
        </div>
    )
}

export default TimberScreen
