import React, { useState } from 'react';
import "./FAQs.css";

import FAQComponent from './FAQComponent';

const FAQs = () => {

    const [radioValue, setRadioValue] = useState("-1");
    const [faqs, setfaqs] = useState([
        {
            que: "How to get started with PLI?",
            ans: 'Signup with user demographics, Choose your profile and Subscribe.<br/> <a target="_blank" rel="noreferrer"  href="https://login.portlinksindia.com/auth/register">Signup Here</a>',
            open: false
        },
        {
            que : "Is it completely 0% commisional portal?",
            ans : "Yes, PLI is Annual subscription based portal; No commision / Brokrage involved.",
            open: false
        },
        {
            que : "Who are the suitable set-of users?",
            ans : "At present Importer, CHA, Trader, Buyer, Stevedores, Loading Agent, Transporter, Driver & Other Service Provider at Port.",
            open: false
        },
        {
            que : "Which are the Add-on & On-Demand Services PLI offers?",
            ans : "PLI provides Add-on services like: Live Loading, Digital Seals & Accurate Unloading, Billing Automation, Tracking Solution, Quality Check Report.\n PLI offers On-Demand service/solution which are requested by PLI registered users after evaluating users requirement.",
            open: false
        },
        {
            que : "How the support is provided?",
            ans : "For Signup and Subscription related support, contact us by live chat, phone, Whatsapp, or email. We also provide training for individuals and teams, as well as technical support during office hours.",
            open: false
        },
    ]);

    const onRadioChange = (e) => {
        setRadioValue(e.target.value);
    }

    const toggleFAQ = index => {
        setfaqs(faqs.map((faq, i) => {
          if (i === index) {
            faq.open = !faq.open
          } else {
            faq.open = false;
          }
    
          return faq;
        }))
      }

    return (
        <div className="faqContainer">
            <div className="faqHeading">Frequently Asked Questions</div>
            <div className="faqQueAnsContainer">
                {/* <div className="faqItem">
                    <div className="faqContent">
                        <div className="faqQue">How to get started with PLI?</div>
                        <div className={"faqAns "+(radioValue===("0")?"showFaq":"")}>
                            Signup with user demographics, Choose your profile and Subscribe.<br/> <a target="_blank" rel="noreferrer"  href="https://www.portlinksindia.com/auth/register">Signup Here</a> 
                        </div>
                    </div>
                    <label className="radioLabel">
                        <input className="radioButton" type="radio" name="faq" value="0" {...check} onChange={onRadioChange} />
                        <img src={plusIcon} alt="open/close" />
                    </label>
                </div> */}
                {faqs.map((faq,index)=>{
                    return <FAQComponent key={index} pos={index} que={faq.que} ans={faq.ans} 
                                        radioValue={radioValue} onRadioChange={onRadioChange} 
                                        open={faq.open} toggleFAQ={toggleFAQ} />
                })}
            </div>
        </div>
    )
}

export default FAQs
