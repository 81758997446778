import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router'
import NewsLetterBar from '../../component/news/NewsLetterBar/NewsLetterBar'
import RecomNews from '../../component/news/RecomNews/RecomNews'
import ShowNews from '../../component/news/ShowNews/ShowNews'
import SocialMedia from '../../component/news/SocialMedia/SocialMedia'

const ShowNewsScreen = () => {

    const [newsData, setNewsData] = useState({})
    const [showProgress, setShowProgress] = useState(true);
    const param = useParams();
    const history = useHistory();


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL+"/api/news/"+param.id)
        .then((response)=>{
            setNewsData(response.data.news);
            setShowProgress(false);
        })
        .catch(err=>{
            if(err.response.status===503)
                history.push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.msg }});
            else{
                history.push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.msg }});
            }
        })
    },[param.id]);

    return (
        <>
            <Helmet>
                {/* <meta property="og:type" content="website" />
                <meta property="og:url" content={"https://www.portlinksindia.com/blog/"+param.id}/>
                <meta property="og:image:secure_url" itemprop="image" content={process.env.REACT_APP_API_BASE_URL+"/blogImages/"+blogData.thumbnail}/>
                <meta property="og:title" content="PORTLINKS INDIA Blog"/>
                <meta property="og:site_name" content="PORTLINKS INDIA" /> */}
                <meta name="description" content={newsData.title} />
            </Helmet>
            <ShowNews showProgress={false} newsData={newsData} />
            <SocialMedia id={param.id} />
            <NewsLetterBar />
            <RecomNews category={newsData.category} id={param.id} />
        </>
    )
}

export default ShowNewsScreen
