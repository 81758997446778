import React from 'react'
import BlogNavbar from '../../component/blog/BlogNavbar/BlogNavbar'

import ServiceList from '../../component/service/ServiceListing/ServiceListing'

const ServiceScreen = () => {

    
    return (
        <div className='services'>
        <BlogNavbar />
          <ServiceList />
        </div>
    )
}

export default ServiceScreen
