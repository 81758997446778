import React from 'react'
import { Link } from 'react-router-dom'

const CareerCard = ({jobData}) => {
    return (
        <div className="careerCard">
            <div className="careerCardTitle">{jobData.title}</div>
            <Link to={"/career/"+jobData._id}><div className="careerCardMoreBtn">Learn More</div></Link>
        </div>
    )
}

export default CareerCard
