/* global google */
import axios from 'axios';

import React, { Component, createRef } from 'react';

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Legends from './MapComps/Legends';
import OnePort from './MapComps/OnePort';
import Outline from './MapComps/Outline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import portImg from '../../images/pngs/planMaking.png';
import "./PortsSVG.css";

import minor from "../../images/svgs/minor-port.svg";
import major from "../../images/svgs/major-port.svg";
import privateport from "../../images/svgs/private-port.svg";

import minorShape from "../../images/svgs/square-shape.svg";
import majorShape from "../../images/svgs/round-shape.svg";
import privateShape from "../../images/svgs/diamond-shape.svg";
// import { LoadScript } from "react-google-maps/api";

// const { isLoaded } = useLoadScript({
//     googleMapsApiKey: "AIzaSyDkrAR0CKDqCogtUVLxZP2a2H__jLmhDqY" // Add your API key
// });

const _ = require("lodash");
const { compose, withProps, lifecycle } = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
} = require("react-google-maps");


// const useStyles = makeStyles(theme => ({
//     vesselheading: {
//         backgroundColor: "#3d88ec",
//         textAlign: "center",
//         color: "#ffffff",
//         fontSize: "25px",
//         padding: "5px",
//     }
// }));

// const MyMapComponent = compose(
//     withProps({
//         googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDkrAR0CKDqCogtUVLxZP2a2H__jLmhDqY&v=3.exp&libraries=geometry,drawing,places",
//         loadingElement: <div style={{ height: `100%` }} />,
//         containerElement: <div style={{ height: `100%` }} />,
//         mapElement: <div style={{ height: `100%` }} />,
//     }),
//     lifecycle({
//         componentWillMount() {
//             // { console.log("GoogleMapGoogleMap", this.props) }
//             const refs = {}
//             this.setState({
//                 bounds: null,
//                 center: {
//                     lat: this.props.latitude, lng: this.props.longitude
//                 },
//                 markers: [],
//                 onMapMounted: ref => {
//                     refs.map = ref;
//                 },
//                 onBoundsChanged: () => {
//                     this.setState({
//                         bounds: refs.map.getBounds(),
//                         center: refs.map.getCenter(),
//                     })
//                 },
//                 onSearchBoxMounted: ref => {
//                     refs.searchBox = ref;
//                 },
//                 onButtonMounted: ref => {
//                     refs.button = ref;
//                 },
//                 onPlacesChanged: () => {
//                     const places = refs.searchBox.getPlaces();
//                     const bounds = new google.maps.LatLngBounds();

//                     places.forEach(place => {
//                         if (place.geometry.viewport) {
//                             bounds.union(place.geometry.viewport)
//                         } else {
//                             bounds.extend(place.geometry.location)
//                         }
//                     });
//                     const nextMarkers = places.map(place => ({
//                         position: place.geometry.location,
//                     }));
//                     // console.log("nextMarkers", nextMarkers);
//                     const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
//                     // console.log("nextCenter", nextCenter.lat())
//                     this.setState({
//                         center: nextCenter,
//                         markers: nextMarkers,

//                     });
//                     this.props.getPosition(nextCenter)
//                     // refs.map.fitBounds(bounds);
//                 },
//                 onPlacesChanged1: () => {
//                     // const places = refs.getPlaces();
//                     // const bounds = new google.maps.LatLngBounds();

//                     navigator.geolocation.getCurrentPosition((position) => {
//                         // console.log("checkMyLocation", position);
//                         var pos = {
//                             lat: position.coords.latitude,
//                             lng: position.coords.longitude
//                         };
//                         this.setState({ ownPosition: pos })
//                         const nextMarkers = pos;
//                         // console.log("nextMarkers", nextMarkers);
//                         const nextCenter = pos
//                         // console.log("nextCenter", nextCenter.lat())
//                         this.setState({
//                             center: nextCenter,
//                             markers: nextMarkers,

//                         });
//                         this.props.getPosition(nextCenter)
//                     });

//                     // refs.map.fitBounds(bounds);
//                 },
//             })
//         },
//     }),
//     withScriptjs,
//     withGoogleMap
// )(props =>
//     <div>
//         {console.log("GoogleMapGoogleMap1", props, typeof props.latitude)}
//         <GoogleMap
//             ref={props.onMapMounted}
//             defaultZoom={5}
//             center={props.center}
//             onIdle={props.onBoundsChanged}
//         >
//             <div className="locate-btn">
//                 <button ref={props.onButtonMounted} onClick={props.onPlacesChanged1}><i class="icon-myLocation" aria-hidden="true" ></i></button>
//             </div>
//             {props.Data.map((data, Idx) => {
//                 let op = false;
//                 return (
//                     <Marker
//                         key={Idx}
//                         position={{ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }}
//                         icon={data.portType === 'minor' ? minorShape : data.portType === 'major' ? majorShape : data.portType === 'private' ? privateShape : null}
//                         onClick={props.onMarkerOverlay}
//                     //         ()=>{
//                     //         let mapData = props.Data;
//                     //         props.Data[Idx]['isActive'] = !props.Data[Idx]['isActive'];
//                     //         // props.Data = mapData;
//                     //     }
//                     // }

//                     >
//                         {data.isActive ?
//                             <InfoWindow
//                             >
//                                 <div className="map-card-area map-box">
//                                     <div className='map-card'>
//                                         <div className="map-img">
//                                             <img src={portImg} alt="" />
//                                         </div>
//                                         <div className="map-card-content">
//                                             <h3>{data.portName}</h3>
//                                         </div>
//                                         <p>
//                                             {data.portShortDescription}
//                                         </p>
//                                     </div>

//                                 </div>
//                             </InfoWindow> : null}
//                     </Marker>
//                 );
//             })}
//         </GoogleMap>
//     </div>
// );

// const Marker = props => {
//     return <div className="map-marker">
//                                            <img src={majorShape} alt="" />
//     </div>
//   }

// const exampleMapStyles = [
//     {
//         featureType: "all",
//         elementType: "landscape.natural",
//         stylers: [
//             {
//                 visibility:"#ffffff",
//             },
//         ],
//     },
//     {
//         featureType: "poi",
//         elementType: "labels.text",
//         stylers: [
//             {
//                 color: "#000000",
//             },
//         ],
//     },
//     {
//         featureType: "poi",
//         elementType: "labels.text",
//         stylers: [
//             {
//                 color: "#000000",
//             },
//         ],
//     },
//     {
//         featureType: "water",
//         elementType: "labels.text.fill",
//         stylers: [
//             {
//                 color: "#ffffff"
//             },
//         ],
//     },
// ];

const initialState = {
    longitude: 78.9629,
    longitudeErr: false,
    longitudehelperText: '',
    latitude: 20.5937,
    latitudeErr: false,
    latitudehelperText: '',
    accuracy: 1,
    placename: '',
    portErr: false,
    porthelperText: '',
    enter_address: '',
    addressErr: false,
    addresshelperText: '',
    items: [],
    getVesselPortList: [],
    token: '',
    autocompleteErr: false,
    tags: '',
    isMarkerShown: false,
    isEdit: false,
    IsAdd: 'Add Existing',
    ports: [],
    defaultLat: 0,
    defaultLng: 0,
    activeMarker: null
}
class PortsSVG extends Component {



    static defaultProps = {
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4&v=3.exp&libraries=geometry,drawing,places",
    }

    constructor(props) {
        super(props)
        this.state = initialState;
        this.imageRef = createRef();
    }

    componentDidMount = () => {

        axios.get(process.env.REACT_APP_API_BASE_URL + '/api/allPorts')
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        ports: response.data.portData.length ? response.data.portData.map(x => {
                            return { ...x, isActive: false }
                        }) : []
                    });
                }

            })
            .catch(error => {
                console.log(error)

            });
    }

    getLatLong = (e) => {
        if (isNaN(e.lat)) {
            this.setState({ latitude: e.lat().toFixed(4), longitude: e.lng().toFixed(4), latitudeErr: false, longitudeErr: false, latitudehelperText: '', longitudehelperText: '', mooringCoordinatesError: false });
        }
        else {
            this.setState({ latitude: e.lat.toFixed(4), longitude: e.lng.toFixed(4), latitudeErr: false, longitudeErr: false, latitudehelperText: '', longitudehelperText: '', mooringCoordinatesError: false });
        }
    }

    onMarkerOverlay = (item) => {
        let lat = item.latLng.lat();
        let lng = item.latLng.lng();
        let mapData = this.state.ports;
        let idx = mapData.findIndex(x => Number(x.latitude) == lat && Number(x.longitude) == lng);
        if (idx != -1) {
            mapData[idx]['isActive'] = !mapData[idx]['isActive'];
            this.setState({ ports: mapData })
        }
    }

    handleOnLoad = (map) => {
        const bounds = new google.maps.LatLngBounds();
        this.state.ports.forEach(({ position }) => bounds.extend({ lat: position.latitude, lng: position.longitude }));
        map.fitBounds(bounds);
    };

    CMap = withScriptjs(withGoogleMap(props =>
        <GoogleMap
        // options={{
        //     styles: exampleMapStyles,
        // }}
            defaultZoom={5}
            defaultCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
        >
            {props.children}
        </GoogleMap>
    ));

    render() {
        return (
            <div className='portsMapContainer'>
                <Container>
                    <div className='section-head'>Our <span> Presence </span> At <span> Global </span> Stage</div>
                    <div className="global-head">
                        <div className='markers'>
                            <div className='markers'>

                                <div> <img src={major} alt="" /></div>
                                <div> Major </div>
                            </div>

                            <div className='markers'>

                                <div> <img src={minor} alt="" /></div>
                                <div> Minor </div>
                            </div>
                            <div className='markers'>

                                <div> <img src={privateport} alt="" /></div>
                                <div>  Private </div>
                            </div>
                        </div>
                        {/* <div className='filter-btn'>
                            Port Filter
                        </div> */}
                    </div>
                    <Grid container >
                        <Grid xs={12} md={8}>

                            <div className="map-area">
                                <this.CMap
                                    googleMapURL={this.props.googleMapURL}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `100%` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                >
                                    {this.state.ports.map((data, idx) => (
                                        <Marker
                                            key={idx}
                                            position={{ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }}
                                            icon={data.portType === 'minor' ? minorShape : data.portType === 'major' ? majorShape : data.portType === 'private' ? privateShape : null}
                                            onClick={() => this.setState({ activeMarker: idx })}
                                        >
                                            {this.state.activeMarker === idx ? (
                                                <InfoWindow onCloseClick={() => this.setState({ activeMarker: null })}>
                                                    <div className="map-card-area map-box">
                                                        <div className='map-card'>
                                                            <div className="map-img">
                                                                <img src={portImg} alt="" />
                                                            </div>
                                                            <div className="map-card-content">
                                                                <h3>{data.portName}</h3>
                                                            </div>
                                                            <p>
                                                                {data.portShortDescription}
                                                            </p>
                                                        </div>

                                                    </div>
                                                </InfoWindow>
                                            ) : null}
                                        </Marker>))}
                                </this.CMap>
                                {/* <MyMapComponent
                                    Data={this.state.ports}
                                    // onMarkerOverlay={this.onMarkerOverlay}
                                    latitude={parseFloat(this.state.latitude)}
                                    longitude={parseFloat(this.state.longitude)}
                                /> */}
                            </div>

                        </Grid>
                        <Grid xs={12} md={4}>

                            <div className="map-details">
                                {/* <div className="map-card-area active">
                                    <div className='map-card'>
                                        <div className="map-img">
                                            <img src={portImg} alt="" />
                                        </div>
                                        <div className="map-card-content">
                                            <h3>Deendayal Port</h3>
                                            <div className="port-type">
                                                <div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="8" cy="8" r="8" fill="#EF9626" />
                                                </svg>
                                                </div>
                                                <div className='text-orange'>Major port</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="port-location">Kandla</div>
                                </div> */}
                                
                                {this.state.ports.map((data, Idx) => {
                                    return (
                                        <div className="map-card-area" onClick={() => {
                                            window.location.pathname = '/port/' + data._id;
                                        }}>
                                            <div className='map-card'>
                                                <div className="map-img">
                                                    <img src={portImg} alt="" />
                                                </div>
                                                <div className="map-card-content">
                                                    <h3>{data.portName}</h3>
                                                    <div className="port-type">
                                                        {data.portType === 'minor' ? <img src={minor} alt="" /> : data.portType === 'major' ? <img src={major} alt="" /> : data.portType === 'private' ? <img src={privateport} alt="" /> : null}
                                                        <div className={data.portType === 'minor' ? 'text-purple port-text' : data.portType === 'major' ? 'text-orange port-text' : data.portType === 'private' ? 'text-blue port-text' : null}>{data.portType}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="port-location">Kandla</div> */}
                                        </div>)
                                })}

                            </div>
                        </Grid>
                    </Grid>


                </Container >
            </div >
        )
    }
}
export default PortsSVG