import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import AllBlogs from '../../component/blog/AllBlogs/AllBlogs';
import BlogNavbar from '../../component/blog/BlogNavbar/BlogNavbar'
import NewsLetterBar from '../../component/blog/NewsLetterBar/NewsLetterBar';

const AllBlogScreen = () => {

    const [filterBlog, setFilterBlog] = useState("All");
    const [searchBlog, setSearchBlog] = useState("");
    const [sortBlog, setSortBlog] = useState("date");

    const [showProgress, setShowProgress] = useState(true);
    const [loadShowProgress, setLoadShowProgress] = useState(false);

    const [allBlogs, setAllBlogs] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [shownData, setShownData] = useState([]);

    const initialLoad = 9;
    const laterLoad = 3;
    const history = useHistory();

    const [loadMore, setLoadMore] = useState(0);
    const [showLoadMoreBtn, setShowLoadMoreBtn] = useState({
        "All": true,
        "Coal": true,
        "Salt": true,
        "Timber": true
    });

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_BASE_URL+"/api/allTopBlogs",{initialLoad})
        .then((response)=>{
            
            setAllBlogs(response.data.blogData);
            setShowProgress(false);
            
        })
        .catch(err=>{
            history.push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.err.message }});
        });
    },[])

    useEffect(()=>{
        if(loadMore!==0){
            setLoadShowProgress(true);

            var skipNo = filterBlog==="All"?(initialLoad + (laterLoad * (loadMore - 1))):filteredData.length;

            axios.post(process.env.REACT_APP_API_BASE_URL+"/api/loadMoreBlogs",{ laterLoad, skipNo, category: filterBlog })
            .then((response)=>{ 
                var loadBlogData = response.data.moreBlogData;
                if(loadBlogData.length < laterLoad){
                    if(filterBlog==="All"){
                        setShowLoadMoreBtn({
                            "All": false,
                            "Coal": false,
                            "Salt": false,
                            "Timber": false
                        })
                    }
                    setShowLoadMoreBtn(prev => {
                        return {...prev, [filterBlog]:false}
                    });
                }
                setAllBlogs(prevData => [...prevData, ...loadBlogData])
                setLoadShowProgress(false);
            })
            .catch(err=>{
                history.push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.err.message }});
            })
        }
    },[loadMore])

    useEffect(() => {
        if(filterBlog==="All"){
            setFilteredData(allBlogs);
        }
        else{
            const localFilteredData = allBlogs.filter(blog=> (blog.category===filterBlog));
            setFilteredData(localFilteredData);
        }
    }, [filterBlog, allBlogs])

    useEffect(() => {
        var localSearchedData = filteredData;

        localSearchedData = localSearchedData.filter(
            data => (data.title.toLowerCase().includes(searchBlog.toLowerCase()))
        );

        setSearchedData(localSearchedData);
    }, [searchBlog, filteredData]);

    useEffect(() => {
        var localSortedData = searchedData;
        if(sortBlog==="name"){
            localSortedData.sort((a,b )=>{
                if ( a.title < b.title ){
                  return -1;
                }
                else if ( a.title > b.title ){
                  return 1;
                }
                return 0;
            });            
        } else if(sortBlog==="date"){
            localSortedData.sort((a,b)=>{
                return new Date(b.date) - new Date(a.date);
            });
        }
        setShownData(localSortedData);
    },[sortBlog, searchedData]);


    return (
        <>
            <BlogNavbar 
                filter={filterBlog} 
                setFilter={setFilterBlog} 
                search={searchBlog}
                setSearch={setSearchBlog}
                sort={sortBlog}
                setSort={setSortBlog}
            />
            <AllBlogs 
                showLoadMoreBtn={showLoadMoreBtn[filterBlog]}
                loadShowProgress={loadShowProgress} 
                showProgress={showProgress}
                setLoadMore={setLoadMore} 
                shownData={shownData}
            />
            <NewsLetterBar />
        </>
    )
}

export default AllBlogScreen
