import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import "./AllNews.css";

import NewsOver from "../../common/NewsOver/NewsOver";
import noNews from "../../images/svgs/noBlogs.svg";
import { useHistory } from "react-router";

const AllNews = ({setLoadMore, loadShowProgress, showProgress, shownData, showLoadMoreBtn}) => {

    const history = useHistory();

    return (
        <>
            <CircularProgress  sx={{color:"#415AA9",margin:"5em auto",display:(showProgress?"inherit":"none")}} />
            {
                shownData.length===0
                ? showProgress
                    ? <div></div>
                    : <div className="noNewsFound" >
                        <img src={noNews} alt="No News" />
                        <div className="noNewsFoundMsg" >Oops!! Looks like no news are added yet</div>
                        <div className="bacKToHomeBtn" onClick={()=>history.push("/")}>Back To Home</div>
                    </div>
                :
                <>
                    <div className="allNewsContainer">
                    {
                        shownData.map((newsData)=>{
                            return(
                                <NewsOver 
                                key={newsData.id} newsData={newsData} 
                                />
                             )
                        })
                    }
                    </div>
                    <CircularProgress  sx={{color:"#415AA9",margin:"5em auto",display:(loadShowProgress?"inherit":"none")}} />
                    <div className="allNewsLoadMore" style={{display:(showLoadMoreBtn?"inherit":"none")}} onClick={()=>setLoadMore(prev=>prev+1)}>Load More</div>
                </>
            }
        </>
    )
}

export default AllNews
