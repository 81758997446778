import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BlogOver from "../../common/BlogOver/BlogOver";
import "./RecomBlog.css";

const LatestBlog = ({category,id}) => {

    const [recomBlog, setRecomBlog] = useState([]);
    const { push } = useHistory();

    useEffect(() => {
        const formData = {
            id: id,
            category: category
        }

        axios.post(process.env.REACT_APP_API_BASE_URL+"/api/recomendedBlogs",formData)
        .then((response)=>{
            setRecomBlog(response.data.recomendedBlogData);
        })
        .catch(err=>{
            push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.err.message }});
        })
    },[category, id, push]);
    
    return (
        <div className="recomBlogContainer">
            <div className="recomBlogs">
                {
                    recomBlog.map((blogData,index)=>{
                        return ( <BlogOver key={index} blogData={blogData} /> ) 
                    })
                }
            </div>
        </div>
    )
}

export default LatestBlog
