import React from 'react'
import { useEffect, useState, props } from 'react'
import axios from 'axios';
import { useHistory, useParams } from 'react-router';
import "./assests/ProductAbout.css";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import "./assests/ProductWork.css";
import "./assests/ServiceKeyPoints.css";
import productwork from "./assests/productwork1.svg";
import productMainImg from "./assests/product1.svg";
import CommonConfig from '../../Utils/CommonConfig'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const productDetails = {
    id: 1,
    aboutProduct: " It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
    gpsTypes: [
        {
            id: 0,
            typeName: "Vehicle Tracker: 2G Wired Tracker",
            description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
            image: productwork,
            workDetail: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum.",
            features: ["Fast Tracking", "Easy to Use", "Economical", "Convenient Design", "High data transmission rate", "Prominent Brand"]
        },
        {
            id: 1,
            typeName: "Vehicle Tracker: 3G Wired Tracker",
            description: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum.",
            image: productwork,
            workDetail: "1.............................Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum.",
            features: ["Fast Tracking 1", "Easy to Use", "Economical", "Convenient Design", "High data transmission rate", "Prominent Brand"]
        }
    ]
};
const ProductDetails = () => {
    const [productList, SetProductList] = useState({});
    const [productTypeList, SetProductTypeList] = useState([]);
    const [getLatestProductList, SetLatestProductList] = useState([]);
    const [selectedProductIdx, SetSelectedProductIdx] = useState(0);
    const [formatTitle, setFormatTitle] = useState("");
    const [showProgress, setShowProgress] = useState(false);
    const param = useParams();
    const initialLoad = 9;
    const laterLoad = 3;
    const history = useHistory();

    useEffect(() => {
        if (param.id) {
            getProductsByID(param.id);
            allProductsType(param.id);
            LatestProductList(param.id);
        }
    }, []);
    const getProductsByID = (ID) => {
        axios.post(process.env.REACT_APP_API_BASE_URL + "/api/getProductsByID", { ID: ID })
            .then((response) => {
                SetProductList(response.data.productData);

            })
            .catch(err => {
            });
    }
    const LatestProductList = (ID) => {
        axios.post(process.env.REACT_APP_API_BASE_URL + "/api/LatestProductList", { ID: ID })
        .then((response) => {
            
            if (response?.data?.productData?.length) {
                SetLatestProductList(response.data.productData);

            }

        })
        .catch(err => {

        });
    }
    const allProductsType = (ID) => {
        axios.post(process.env.REACT_APP_API_BASE_URL + "/api/allProductsType", { ID:ID })
            .then((response) => {
                if (response?.data?.productData?.length) {
                    SetProductTypeList(response.data.productData);
                    setShowProgress(true);
                }

            })
            .catch(err => {

            });
    }


    return (
        <>
            <div className="lightBlueBgBannerContainer">
                <div className="lightBlueBgBannerHeadline">{productList.productName}
                </div>
            </div>
            <div className="product-about-container">
                <Container>
                    <div>
                        <Grid container  >
                            <Grid xs={12} md={6}>
                                <div className="product-main-img">
                                    <img src={process.env.REACT_APP_API_BASE_URL + "/productImages/" + productList.productImage} alt={productList.productImage}

                                    />

                                </div>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <div className="product-main-info">
                                    {productList.productHeadingName}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='mt-5'>
                        <div className="main-header">
                            About Product
                        </div>
                        <p className="product-about">
                            {productList.productDescription}
                        </p>
                    </div>
                    {showProgress ? <>   <div className='product-details'>
                        <div className="main-header">
                            {productList.productName} Product
                        </div>

                        <div className="about-product-tags-area">
                            {!productTypeList.length ? null : Object.keys(productTypeList).map((x) => {
                                return (
                                    <div className={x == selectedProductIdx ? "about-product-tag active" : "about-product-tag inactive"} key={x} onClick={() => SetSelectedProductIdx(x)}>
                                        {productTypeList[x].productTypeHeadingName}
                                    </div>
                                )
                            })}

                        </div>
                        </div>
                        <div className="product-info-container">
                            <Container>
                                <div className="main-header">
                                    How it ‘s work
                                </div>
                                <p className="product-works-detail" dangerouslySetInnerHTML={{ __html: productTypeList[selectedProductIdx]?.productTypeContent }}>
                                </p>

                            </Container>
                        </div>
                        <div className='service-key-pionts-container'>
                            <Container>

                                <div className="main-header">
                                    Key Points

                                </div>

                                <ul>
                                    {productTypeList[selectedProductIdx]?.productTypeKeyPoint.split(',').map(x => {
                                        return (
                                            <li>
                                                {x}
                                            </li>
                                        )
                                    })}
                                </ul>


                            </Container>
                        </div>
                    
                        <div className="get-quot-section">
                            <h2>Get Quick Quotation </h2>
                            <p>Variations of passages of Lorem Ipsum available</p>
                            <div className='quot-btn'><a href={process.env.REACT_APP_API_BASE_URL + "/productTypeFiles/" + productTypeList[selectedProductIdx]?.productTypeQuation} target='_blank' download >   <div>Get me a quote</div></a>

                            </div>
                        </div></> : null}
                    <div className="product-more-container">
                        <Container>
                            <div className="main-header">
                                More Products
                            </div>

                            <Grid container>
                                {getLatestProductList.map((data, Idx) => {
                                    return (
                                        <Grid xs={12} sm={6} md={4} onClick={ () =>{
                                            setShowProgress(false);
                                            getProductsByID(data._id);
                                            allProductsType(data._id);
                                            LatestProductList(data._id);}}>
                                            <div className="px-2">
                                            <div className="more-product-card">
                                                <div className="product-img">
                                                    <img src={process.env.REACT_APP_API_BASE_URL + "/productImages/" + data.productImage} alt={data.productImage} />
                                                </div>
                                                <div className="product-title">
                                                    <div>
                                                        <h3>{data.productName}</h3>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            {data.productDescription.length > 70 ? (data.productDescription.substring(0, 70) + " ...") : data.productDescription}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div></div>
                                        </Grid>
                                    )
                                })}
                                {/* <Grid xs={12} md={6}>
                    </Grid> */}
                            </Grid>
                        </Container>
                    </div>
                </Container>
            </div>


        </>
    )
}

export default ProductDetails