import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import PILLogo from "../../images/svgs/PLI_New_Logo.svg";
import caretDown from "../../images/svgs/icons/caretDown.svg";
import caretRight from "../../images/svgs/icons/caretRight.svg"
import hamburgerMenu from "../../images/svgs/icons/hamburger-menu.svg";
import downArrow from "./images/downArrow.svg";
import upArrow from "./images/upArrow.svg";

import "../Navbar/Navbar.css";
import { useEffect } from 'react';
import { useRef } from 'react';
import ReactGA from "react-ga";
import { withRouter } from 'react-router-dom';

const Navbar = () => {

    const [showScrollTop, setShowScrollTop] = useState(false);
    const [circularMenuIdx, setCircularMenuIdx] = useState(0);
    const [circularMenuList, setCircularMenuList] = useState([
        {
            menuName: "Market Place",
            path: "/features"
        },
        {
            menuName: "Product",
            path: "/product"
        },
        {
            menuName: "Service",
            path: "/service"
        },
        {
            menuName: "Pricing",
            path: "/pricing"
        },
    ]);

    const [showNavbar, setShowNavbar] = useState(false);
    const [showDropDownList, setShowDropDownList] = useState(false);

    const navbarRef = useRef();
    const dropDownRef = useRef();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("scroll", () => {
            var pos = Math.ceil(window.scrollY);
            if (pos > 100)
                setShowScrollTop(true);
            if (pos < 100)
                setShowScrollTop(false);
        })
    })

    useEffect(() => {
        function handleClickOutside(event) {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setShowNavbar(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [navbarRef]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setShowDropDownList(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRef]);

    const history = useLocation();

    const circularFlip = (type) => {
        if (type == "up") {
            if (circularMenuIdx > 0) {
                setCircularMenuIdx(circularMenuIdx - 1);
            }
            else {
                setCircularMenuIdx(circularMenuList.length - 1);
            }
        }
        else {
            if (circularMenuList.length - 1 != circularMenuIdx) {
                setCircularMenuIdx(circularMenuIdx + 1);
            }
            else {
                setCircularMenuIdx(0);
            }
        }
    }

    return (
        <>
            <div className={"navbarContainer stickyNavbar"} ref={navbarRef} id="pageTop" >
                <div className="navbar">
                    <div className="navbarLogo" >
                        <Link to="/"><img src={PILLogo} onClick={() => setShowNavbar(false)} alt="PIL" /></Link>
                    </div>
                    <div className="hamburgerMenu" onClick={() => setShowNavbar(!showNavbar)}><img src={hamburgerMenu} alt="menu" /></div>

                    <div className={"navbarOptions " + (showNavbar ? "showNavbar" : "")}>
                        <div className="navbarLinks">
                            <div className={"navbarLinkItems " + (history.pathname === "/features" ? "selected" : "")} >
                                <Link to="/" onClick={() => setShowNavbar(false)}> Home </Link>
                            </div>
                            {/* <div className={"navbarLinkItems "+(history.pathname==="/features"?"selected":"")} >
                                <Link to="/features" onClick={()=>setShowNavbar(!showNavbar)}> Features </Link>
                            </div> */}
                            <div className={"navbarLinkItems arrow-nav " + (history.pathname === "/features" ? "selected" : "")} >
                                <div className=''>
                                    <img src={upArrow} className="cursor up-arrow" onClick={() => circularFlip("up")} />
                                </div>
                                {circularMenuList.map((x, idx) => {
                                    return (
                                        <div className={idx == circularMenuIdx ? "circuler-menu-item-" + idx + " circuler-menu-item active" : "circuler-menu-item-" + idx + " circuler-menu-item"}>
                                            <Link to={x.path} key={idx} >{x.menuName} </Link>
                                        </div>
                                        // <Link to={x.path} key={idx} className={idx == circularMenuIdx ? "circuler-menu-item active" : "circuler-menu-item"} >{x.menuName} </Link>
                                    )
                                })}
                                <div className=''>
                                    <img src={downArrow} className="cursor down-arrow" onClick={() => circularFlip("down")} />
                                </div>
                            </div>
                            {/* <div className={"navbarLinkItems " + (history.pathname === "/pricing" ? "selected" : "")} >
                                <Link to="/pricing" onClick={() => setShowNavbar(!showNavbar)}> Pricing </Link>
                            </div> */}
                            <div ref={dropDownRef} className={"navbarLinkItems dropDown " + (history.pathname.indexOf("/commodities") !== -1 ? "selected" : "")} >
                                <div className="dropDownBtn" onClick={() => setShowDropDownList(!showDropDownList)}>
                                    Indexes
                                    <img className={"caretDownLogo " + (showDropDownList ? "rotateArrow" : "")} src={caretDown} alt="show/hide" />
                                </div>
                                <div className={"dropDownList " + (showDropDownList ? "showDropDownList" : "")} >
                                    <div className={"dropDownItem " + (history.pathname === "/commodities/coal" ? "selected" : "")} >
                                        <Link to="/commodities/coal" onClick={() => { setShowNavbar(!showNavbar); setShowDropDownList(false) }}> Coal </Link>
                                    </div>
                                    <div className={"dropDownItem " + (history.pathname === "/commodities/timber" ? "selected" : "")} >
                                        <Link to="/commodities/timber" onClick={() => { setShowNavbar(!showNavbar); setShowDropDownList(false) }}> Timber </Link>
                                    </div>
                                    <div className={"dropDownItem " + (history.pathname === "/commodities/salt" ? "selected" : "")} >
                                        <Link to="/commodities/salt" onClick={() => { setShowNavbar(!showNavbar); setShowDropDownList(false) }}> Salt </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={"navbarLinkItems " + (history.pathname === "/blog" ? "selected" : "")} >
                                <Link to="/blog" onClick={() => setShowNavbar(!showNavbar)}> Blog </Link>
                            </div>
                            <div className={"navbarLinkItems " + (history.pathname === "/news" ? "selected" : "")} >
                                <Link to="/news" onClick={() => setShowNavbar(!showNavbar)}> News </Link>
                            </div>
                            <div className={"navbarLinkItems " + (history.pathname === "/weather" ? "selected" : "")} >
                                <Link to="/weather" onClick={() => setShowNavbar(!showNavbar)}> Weather </Link>
                            </div>
                            <div style={{ whiteSpace: 'nowrap' }} className={"navbarLinkItems " + (history.pathname === "/import-export" ? "selected" : "")} >
                                <Link to="/import-export" onClick={() => setShowNavbar(!showNavbar)}> Import Export </Link>
                            </div>
                            <div className={"navbarLinkItems " + (history.pathname === "/career" ? "selected" : "")} >
                                <Link to="/career" onClick={() => setShowNavbar(!showNavbar)}> Career </Link>
                            </div>
                            {/* <div className={"navbarLinkItems "+(history.pathname==="/about"?"selected":"")} >
                            <Link to="/about"  onClick={()=>setShowNavbar(!showNavbar)}> About </Link>
                        </div> */}
                        </div>
                        <div className="navbarGetStarted removeSelection">
                            <a target="_blank" rel="noreferrer" href="https://login.portlinksindia.com/" > Join Now </a>
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className={"scrollToTop " + (showScrollTop ? "showScrollToTop" : "")} ><img src={caretRight} alt="Page Top" /></div>
        </>
    )
}

export default withRouter(Navbar);
