import React from 'react'
import GetStarted from '../../component/common/GetStarted/GetStarted'
import LightBlueBgBanner from '../../component/common/LightBlueBgBanner/LightBlueBgBanner'
import FAQs from '../../component/common/FAQs/FAQs'
import Pricing from '../../component/pricing/Pricing/Pricing'

const PricingScreen = () => {

    const getStartedData = {
        title:"Need More information?",
        subTitle:"We will help you choose right plan for your company",
        btn:"Cantact Us",
        link: "https://mail.google.com/mail/?view=cm&fs=1&to=info@portlinksindia.com"
    }

    return (
        <div>
            <LightBlueBgBanner headline={"Pricing"} />
            <Pricing />
            <div className="faqGetStartedHelper">
                <GetStarted getStartedData={getStartedData} />
            </div>
            <div className="faqPricingHelper">
                <FAQs />
            </div>
        </div>
    )
}

export default PricingScreen
