import React, { useState } from 'react';

import "./TimberCalc.css";
import leftArrow from "../../../images/svgs/icons/blueLeftArrow.svg";
import dropDownIcon from "../../../images/svgs/icons/grayDownFilledArrow.svg";
import { useRef } from 'react';
import { useEffect } from 'react';

const TimberCalc = () => {

    const [int1DropVal, setInt1DropVal] = useState("NAR");
    const [int2DropVal, setInt2DropVal] = useState("GAR");

    const timberData = ["GCV","NAR","GAR"];

    const [int1Val, setInt1Val] = useState(0);
    const [int2Val, setInt2Val] = useState(0);

    const [openFirst, setOpenFirst] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);

    const firstRef = useRef();
    const secondRef = useRef();
    
    const [dir, setDir] = useState(true);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                firstRef.current && !firstRef.current.contains(event.target) &&
                secondRef.current && !secondRef.current.contains(event.target)
            ) {
                setOpenFirst(false);
                setOpenSecond(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [firstRef, secondRef]);


    useEffect(() => {
        if(dir)
            inputChanged(int1Val);
        else    
            inputChanged(int2Val);
    }, [int1DropVal, int2DropVal]);


    const inputChanged = (value)=>{

        var input = parseInt(value,10);
        var result = input;
        var from;
        var to;

        if(input!=="NAN"){
            if(dir){
                setInt1Val(input)
            } else{
                setInt2Val(input)
            }

            if(dir) {
                from = int1DropVal;
                to = int2DropVal;
            }
            else {
                from = int2DropVal;
                to = int1DropVal;
            }

            
            if(input===0){
                result = 0
            }
            else if(from==="GAR"){
                if(to==="GAR"){
                    result = input
                }
                else if(to==="NAR"){
                    if(input<=5000){
                        result = input - 400;
                    } else {
                        result = input - 300;
                    }
                }
            } else if(from==="NAR"){
                if(to==="NAR"){
                    result = input
                }
                else if(to==="GAR"){
                    if(input<=5000){
                        result = input + 400;
                    } else {
                        result = input + 300;
                    }
                }
            }

            if(dir){
                setInt2Val(result)
            } else{
                setInt1Val(result)
            }
        }
    }

    return (
        <div className="TimberCalcContainer">
            <div className="TimberCalcHead">Timber Quality Calculator</div>
            <div className="TimberCalcMain">
                <div className={"calcSide calcLeft "+(dir?"":"disableInput")}>
                    <div className="calcDirection">{dir?"From":"To"}</div>
                    <div className="calcDropDown" ref={firstRef}>
                        <div className="dropDownBtn" onClick={()=>setOpenFirst(!openFirst)}>
                            {int1DropVal}
                            <img src={dropDownIcon} alt="Select" style={{transform:(openFirst?"rotate(180deg)":"")}} />
                        </div>
                        <div className={"dropDownOptions "+(openFirst?"openDropDown":"")}>
                            {
                                timberData.map((data,index)=>{
                                    return(
                                        <div key={data} className={"dropDownOptionItem removeSelection "+(int1DropVal===data?"selectedOption":"")} 
                                            onClick={()=>{
                                                setInt1DropVal(data);
                                                setOpenFirst(false);
                                            }} >
                                            {data}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <input type="number" disabled={!dir} value={int1Val} onChange={(e)=>inputChanged(e.target.value)} min={0} />
                </div>
                <div className="calcSeperator"></div>
                <img src={leftArrow} alt="convert" className={dir?"":"changeDir"} onClick={()=>setDir(!dir)} />
                <div className={"calcSide calcRight "+(dir?"disableInput":"")} >
                    <div className="calcDirection">{dir?"To":"From"}</div>
                    <div className="calcDropDown" ref={secondRef}>
                        <div className="dropDownBtn" onClick={()=>setOpenSecond(!openSecond)}>
                            {int2DropVal}
                            <img src={dropDownIcon} alt="Select" style={{transform:(openSecond?"rotate(180deg)":"")}} />
                        </div>
                        <div className={"dropDownOptions "+(openSecond?"openDropDown":"")}>
                            {
                                timberData.map((data,index)=>{
                                    return(
                                        <div key={index} className={"dropDownOptionItem removeSelection "+(int2DropVal===data?"selectedOption":"")} 
                                            onClick={()=>{
                                                setInt2DropVal(data);
                                                setOpenSecond(false)
                                            }} >
                                            {data}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <input type="number" disabled={dir} value={int2Val} onChange={(e)=>inputChanged(e.target.value)} min={0} />
                </div>
            </div>     
        </div>
    )
}

export default TimberCalc
