import React from 'react'
import { useParams } from 'react-router'    
import SaltScreen from './SaltScreen';
import CoalScreen from './CoalScreen';
import TimberScreen from './TimberScreen';

const CommodityScreen = () => {

    const { commodity } = useParams();

    return (
        <div>
            {commodity==="coal"?<CoalScreen />:(commodity==="timber"?<TimberScreen />:<SaltScreen />)}
            {/* <CoalScreen /> */}
            
        </div>
    )
}

export default CommodityScreen
