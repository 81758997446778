export const pricingData = [
    {
        key: "importer",
        user:"Importer",
        price:12000
    },
    {
        key:"cha",
        user:"CHA",
        price:12000
    },
    {
        key:"trader",
        user:"Trader",
        price:5000
    },
    {
        key:"buyer",
        user:"Buyer",
        price:10000
    },
    {
        key:"loadAgent",
        user:"Loading Agent",
        price:3000
    },
    {
        key:"transporter",
        user:"Transporter",
        price:10000
    },
    {
        key:"driver",
        user:"Driver",
        price:5000
    },
];

export const modulesByUserDataS = {
    importer:{data:["Customised Solution", "Vessel Solution","Logistic Solution","Stock Management", "Order Management", "Real-Time Updates (Notification)"
                    , "Daily Loading Management", "Customised Network Management", "Location Management", "Performance Dashboard-Reports"
                    , "Real Time Vessel Wise Rate Updates", "Cutomised Reports", "Personalised Training & Support"
                    , "Truck & Driver Management"], allowed: 13},

    cha: {data:["Customised Solution", "Vessel Solution", "Bill Of Entry Management", "Delivery Order Management", "Token Management"
                    , "Gate Pass Management", "Stock Management", "Real-Time Action Updates", "Daily Loading Management"
                    , "Logistic Solution", "Location Management", "Customised Network Management", "Customised Reports"                   
                    , "Order Management" ], allowed: 13},

    trader: {data:["Customised Solution", "Vessel Solution","Logistic Solution","Stock Management", "Order Management", "Real-Time Updates (Notification)"
                    , "Daily Loading Management", "Closed User Group", "Customised Network Management"
                    , "Location Management", "Performance Dashboard-Reports", "Real Time Vessel Wise Rate Updates","Cutomised Reports"
                    , "Personalised Training & Support"], allowed: 14},

    buyer: {data:["Customised Solution", "Vessel Solution","Logistic Solution","Stock Management", "Order Management", "Real-Time Updates (Notification)"
                    , "Daily Loading Management", "Closed User Group", "Customised Network Management"
                    , "Location Management", "Performance Dashboard-Reports", "Real Time Vessel Wise Rate Updates","Cutomised Reports"
                    , "Personalised Training & Support"], allowed: 14},

    loadAgent: {data:["Customised Solution", "Real-Time Updates (Notification)", "Daily Loading Management", "Customised Network Management"
                    , "Location Management", "Vessel Solution","Logistic Solution","Stock Management","Order Management"
                    ,"Truck & Driver Management", "Trip Management","Closed User Group","Route Management"
                    , "Performance Dashboard-Reports"], allowed: 5},    

    transporter: {data: ["Customised Solution","Order Management", "Real-Time Updates (Notification)", "Truck & Driver Management"
                    , "Customised Network Management", "Location Management", "Route Management", "Performance Dashboard-Reports"
                    , "Technical Support", "New Order with No Commission", "Vessel Solution","Logistic Solution","Stock Management"
                    , "Delivery Order Management"], allowed: 10},

    driver: {data: ["Customised Solution", "Real-Time Action Updates", "Trip Management", "Customised Network Management"
                    , "Location Management", "Performance Dashboard-Reports", "End-to-End Assistant"
                    , "Vessel Solution","Logistic Solution","Stock Management","Order Management"
                    , "Daily Loading Management", "Truck & Driver Management", "Closed User Group"], allowed: 7}
}

export const modulesByUserData = {
    importer: [true, true, true, true, true, true, true, false, true, false, true, true, true, true, false],
    trader: [true, true, true, true, true, true, true, false, true, true, true, true, true, true, false],
    buyer: [true, true, true, true, true, true, true, false, true, true, true, true, true, true, false],
    cha: [true, true, true, true, false, true, true, false, false, false, true, true, false, true, false, true, true, true, true],
    loadAgent: [true, false, false, false, false, true, true, false, false, false, true, true, false, false, false],
    transporter: [true, false, false, false, true, true, false, true, false, false, true, true, true, true, true],
    driver: [true, false, false, false, false, true, false, false, true, false, true, true, false, true, false],
}

export const modulesData = ["Customised Solution","Vessel Solution","Logistic Solution","Stock Management","Order Management",
                            "Real-Time Updates (Notification)","Daily Loading Management","Truck & Driver Management",
                            "Trip Management","Closed User Group","Customised Network Management",
                            "Location Management","Route Management","Performance Dashboard-Reports","Technical Support",
                            "Bill Of Entry Management", "Delivery Order Management", "Token Management", "Gate Pass Management"];