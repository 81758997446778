import React from 'react';
import GetStarted from '../../component/common/GetStarted/GetStarted';
import Features from '../../component/Features/Features/Features';
import Pearks from '../../component/Features/Pearks/Pearks';
import Users from '../../component/Features/Users/Users';
import KeyPoints from '../../component/Features/KeyPoints/KeyPoints';

const FeaturesScreen = () => {

    const getStartedData = {
        title:"Get Started with our Portal",
        subTitle:"Signup on our portal and increase your business",
        btn:"Join Now",
        link: "https://login.portlinksindia.com/"
    }

    return (
        <div>
            <Features />
            <KeyPoints />
            <Pearks />
            <Users />
            <GetStarted getStartedData={getStartedData} />
        </div>
    )
}

export default FeaturesScreen
