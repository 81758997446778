import React from 'react';
import "./Users.css";

const Users = () => {
    
    const usersData = [
        {
            image:"importer",
            title:"Importer",
        },
        {
            image:"cha",
            title:"CHA",
        },
        {
            image:"trader",
            title:"Trader",
        },
        {
            image:"buyer",
            title:"Buyer",
        },
        {
            image:"transporter",
            title:"Transporter",
        },
        {
            image:"loadAgent",
            title:"Loading Agent",
        },
        {
            image:"drivers",
            title:"Driver",
        },
    ]

    return (
        <div className="usersContainer">
            <div className="usersHeadline"></div>
            <div className="feature-section-head">
            Who do we <span>help?</span> 

            </div>
            <div className="usersMain">
                {
                    usersData.map((user,index)=>{
                        return (
                            <div key={user.image} className="usersItem">
                                <div className="user-img">
                                    
                                <img src={require("../../images/svgs/users/"+user.image+".svg").default} alt={user.image} />
                                </div>
                                <div className="usersTitle">{user.title}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Users
