import React from 'react';
import "./LandingAboutComp.css";

const LandingAboutComp = () => {
    return (
        <div className="landingAboutCompContainer">
            <div className="landingAboutCompHead">
                Provisioning a Next-Gen Business Module in Maritime & Supply Chain Industry
            </div>
            <div className="landingAboutCompSubHead">
                Port Links India is driven by the idea of automating the supply chain; allowing the stakeholders of marine trading to leverage the freedom to trade from everywhere. 
            </div>
        </div>
    )
}

export default LandingAboutComp
