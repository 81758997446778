import React from 'react';
import "./Recognition.css";

import Grid from '@mui/material/Grid';

const Recognition = () => {
    
    const recognitionData = ["startUpIndia.png"];

    return (
        <div className="recognitionContainer">
            <div className="recognitionHeadline">Recognized By:</div>
                <div className="recognitionCompanies" >
                
                    <Grid className="recognitionGridContainer" container alignItems="center" >
                    {
                        recognitionData.map((recognition,index)=>{
                            return (
                                <Grid key={index} item xs={12} sm={12} md={12} >
                                    <img src={require("./images/"+recognition).default} alt={recognition} />
                                </Grid>
                            )
                        })
                    }    
                    </Grid>
                </div>
        </div>
    )
}

export default Recognition
