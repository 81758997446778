import React from 'react';
import SaltCalc from '../../component/commodities/salt/SaltCalc/SaltCalc';
import GetStarted from '../../component/common/GetStarted/GetStarted';
import SaltIndex from "../../component/commodities/salt/SaltIndex/SaltIndex"
import LightBlueBgBanner from '../../component/common/LightBlueBgBanner/LightBlueBgBanner'

const SaltScreen = () => {

    const getStartedData = {
        title:"Get Started with our Portal",
        subTitle:"Signup on our portal and increase your business",
        btn:"Join Now",
        link: "https://login.portlinksindia.com/"
    }

    return (
        <div>
            {/* <LightBlueBgBanner headline={"Salt"}/> */}
            <SaltIndex />
            <SaltCalc />
            <GetStarted getStartedData={getStartedData} />
        </div>
    )
}

export default SaltScreen
