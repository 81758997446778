import React from 'react'
import { useLocation } from 'react-router'
import ErrorComp from '../../component/common/ErrorComp/ErrorComp'

const ServerError = () => {

    const location = useLocation();

    const errorData = {
        code: location.state.code,
        msg: location.state.msg,
        img: 500
    }

    return (
        <>
            <ErrorComp errorData={errorData} />
        </>
    )
}

export default ServerError
