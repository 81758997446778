import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import whiteLeftArrow from "../../images/svgs/icons/whiteLeftArrow.svg";
import "./ShowCareer.css";

const ShowCareer = () => {

    const descSubHeads=[["roles","The Role 🔎"],["responsibilities","Responsibilities"],
                        ["requirements","Requirements"],["benefits","Benefits"]];

    const param = useParams();
    const [jobData, setJobData] = useState({})
    const [showProgress, setShowProgress] = useState(true);

    const [formatedDate, setFormatedDate] = useState("");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
                        "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        var newDate = day+" "+monthNames[month-1]+", "+year;

        return newDate;
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL+"/api/job/"+param.id)
        .then((response)=>{
            if(response.status===200){
                setJobData(response.data.job);
                setFormatedDate(formatDate(response.data.job.date));
                setShowProgress(false);
            }
            else{
                console.log("Error"+response)
            }
        })
        .catch(err=>{
            console.log(err);
        })
    },[])

    return (
        <div className="showCareerContainer">
            <div className="showCareerHeader">
                <div className="showCareerSubHeader">
                    <Link to="/career" ><img src={whiteLeftArrow} alt="go back" /></Link>
                    <div className="showCareerHeaderDetails">
                        <div className="showCareerHeaderTitle">{jobData?.title}</div>
                        <div className="showCareerHeaderLocation">{jobData?.location}</div>
                        <div className="showCareerHeaderDate">{formatedDate}</div>
                    </div>
                </div>
            </div>
            <CircularProgress  sx={{color:"#415AA9", margin:"5em 0",display:(showProgress?"initial":"none")}} />
            <div className="showCareerDescription">
                <div className="showCareerDecs">Description</div>
                {
                    descSubHeads.map((data,index)=>{
                        if(jobData[data[0]]===""){
                            return <></>
                        }
                        return (
                            <div key={index} className="showCareerDecSub">
                                <div className="showCareerDecSubHead">{data[1]}</div>
                                <div className="showCareerDecContent" dangerouslySetInnerHTML={{__html: jobData[data[0]]}}></div>
                            </div>
                        )
                    })
                }
            </div>
            <a target="_blank" rel="noreferrer"  href={"https://mail.google.com/mail/?view=cm&fs=1&to=info@portlinksindia.com&su=CV for "+jobData.title+" Role&body=PFA my CV"}>
                <div className="submitCVBtn">Submit Your CV</div>
            </a>
        </div>
    )
}

export default ShowCareer
