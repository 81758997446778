import React from 'react';
import "./LightBlueBgAboutBanner.css";

const LightBlueBgAboutBanner = () => {
    return (
        <div className="lightBlueBgAboutBannerContainer">
            <div className="lightBlueBgAboutBannerHead">
                Portlinks India is a process driven e-commerce market place for high sea sell commodities. PLI is devoted to cater trade oppertunities to B2B & B2C group of enitities. 
            </div>
        </div>
    )
}

export default LightBlueBgAboutBanner
