import React from 'react'
import LightBlueBgBtnBanner from '../../component/common/LightBlueBgBtnBanner/LightBlueBgBtnBanner'
import "./TearmsNConds.css";
import { useEffect, useState, props } from 'react'
import axios from 'axios';
import { useHistory, useParams } from 'react-router';


const TNCScreen = () => {
    const [TND, SetTND] = useState({});
    const [productTypeList, SetProductTypeList] = useState([]);
    const [selectedProductIdx, SetSelectedProductIdx] = useState(0);
    const [formatTitle, setFormatTitle] = useState("");
    const [showProgress, setShowProgress] = useState(true);
    const param = useParams();
    const initialLoad = 9;
    const laterLoad = 3;
    const history = useHistory();
    useEffect(() => {
        axios.post(process.env.REACT_APP_API_BASE_URL + "/api/getTermsConditionByID")
        .then((response) => {
            SetTND(response.data.TermsCondition);
            setShowProgress(false);
        })
        .catch(err => {
           
        });
    }, []);
    
    return (
        <>
            <LightBlueBgBtnBanner headline={TND.termsConditionsHeding} />
            <div className="tNcContentMain" dangerouslySetInnerHTML={{__html: TND.termsConditionsContent}}>
      
           </div>
        </>
    )
}

export default TNCScreen
