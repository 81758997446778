import React from 'react';
import { useHistory } from 'react-router';

import "./LightBlueBgBtnBanner.css";
import blackLeftArrow from "../../images/svgs/icons/blackLeftArrow.svg";


const LightBlueBgBtnBanner = (props) => {

    const history = useHistory();

    return (
        <div className="lightBlueBgBannerContainer">
            {/* <div className="ppGoBackBtn" onClick={()=>history.goBack()} ><img src={blackLeftArrow} alt="go back" /></div> */}
            <div className="lightBlueBgBannerHeadline">{props.headline}</div>
        </div>
    )
}

export default LightBlueBgBtnBanner
