import React from 'react';
import "./GetStarted.css";

const GetStarted = ({getStartedData}) => {
    return (
        <div className="getStartedContainer">
            <div className="getStartedTitle">{getStartedData.title}</div>
            <div className="getStartedSubTitle">{getStartedData.subTitle}</div>
            <a target="_blank" rel="noreferrer" href={getStartedData.link}><div className="getStartedBtn">{getStartedData.btn}</div></a>
        </div>
    )
}

export default GetStarted;