import React from 'react';

import plusIcon from "../../images/svgs/icons/plus.svg";

const FAQComponent = (props) => {

    // const check = props.pos===0? {checked:(props.radioValue==="0")} : {};

    return (
        <>
            <div className="faqItem" onClick={() => props.toggleFAQ(props.pos)}>
                    <div className="faqContent">
                        <div className="faqQue" dangerouslySetInnerHTML={{__html:props.que}}></div>
                        <div className={"faqAns "+(props.open?"showFaq":"")} 
                            dangerouslySetInnerHTML={{__html:props.ans}}></div>
                    </div>
                    <div className="radioLabel">
                        {/* <input className="radioButton" type="radio" name="faq" value={props.pos} onChange={props.onRadioChange} /> */}
                        <img src={plusIcon} alt="open/close" className={props.open?"rotate":""} />
                    </div>
            </div>
        </>
    )
}

export default FAQComponent
