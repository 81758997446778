import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import AllNews from '../../component/news/AllNews/AllNews';
import NewsNavbar from '../../component/news/NewsNavbar/NewsNavbar'
import NewsLetterBar from '../../component/news/NewsLetterBar/NewsLetterBar';

const AllNewsScreen = () => {

    const [filterNews, setFilterNews] = useState("All");
    const [searchNews, setSearchNews] = useState("");
    const [sortNews, setSortNews] = useState("date");

    const [showProgress, setShowProgress] = useState(true);
    const [loadShowProgress, setLoadShowProgress] = useState(false);

    const [allNews, setAllNews] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [shownData, setShownData] = useState([]);

    const initialLoad = 9;
    const laterLoad = 3;
    const history = useHistory();

    const [loadMore, setLoadMore] = useState(0);
    const [showLoadMoreBtn, setShowLoadMoreBtn] = useState({
        "All": true,
        "Coal": true,
        "Salt": true,
        "Timber": true
    });

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_BASE_URL+"/api/allTopNews",{initialLoad})
        .then((response)=>{
            
            setAllNews(response.data.newsData);
            setShowProgress(false);
            
        })
        .catch(err=>{
            history.push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.err.message }});
        });
    },[])

    useEffect(()=>{
        if(loadMore!==0){
            setLoadShowProgress(true);

            var skipNo = filterNews==="All"?(initialLoad + (laterLoad * (loadMore - 1))):filteredData.length;

            axios.post(process.env.REACT_APP_API_BASE_URL+"/api/loadMoreNews",{ laterLoad, skipNo, category: filterNews })
            .then((response)=>{ 
                var loadNewsData = response.data.moreNewsData;
                if(loadNewsData.length < laterLoad){
                    if(filterNews==="All"){
                        setShowLoadMoreBtn({
                            "All": false,
                            "Coal": false,
                            "Salt": false,
                            "Timber": false
                        })
                    }
                    setShowLoadMoreBtn(prev => {
                        return {...prev, [filterNews]:false}
                    });
                }
                setAllNews(prevData => [...prevData, ...loadNewsData])
                setLoadShowProgress(false);
            })
            .catch(err=>{
                history.push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.err.message }});
            })
        }
    },[loadMore])

    useEffect(() => {
        if(filterNews==="All"){
            setFilteredData(allNews);
        }
        else{
            const localFilteredData = allNews.filter(news=> (news.category===filterNews));
            setFilteredData(localFilteredData);
        }
    }, [filterNews, allNews])

    useEffect(() => {
        var localSearchedData = filteredData;

        localSearchedData = localSearchedData.filter(
            data => (data.title.toLowerCase().includes(searchNews.toLowerCase()))
        );

        setSearchedData(localSearchedData);
    }, [searchNews, filteredData]);

    useEffect(() => {
        var localSortedData = searchedData;
        if(sortNews==="name"){
            localSortedData.sort((a,b )=>{
                if ( a.title < b.title ){
                  return -1;
                }
                else if ( a.title > b.title ){
                  return 1;
                }
                return 0;
            });            
        } else if(sortNews==="date"){
            localSortedData.sort((a,b)=>{
                return new Date(b.date) - new Date(a.date);
            });
        }
        setShownData(localSortedData);
    },[sortNews, searchedData]);


    return (
        <>
            <NewsNavbar 
                filter={filterNews} 
                setFilter={setFilterNews} 
                search={searchNews}
                setSearch={setSearchNews}
                sort={sortNews}
                setSort={setSortNews}
            />
            <AllNews 
                showLoadMoreBtn={showLoadMoreBtn[filterNews]}
                loadShowProgress={loadShowProgress} 
                showProgress={showProgress}
                setLoadMore={setLoadMore} 
                shownData={shownData}
            />
            <NewsLetterBar />
        </>
    )
}

export default AllNewsScreen
