import React from 'react';
import { useHistory } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import "./ShowBlog.css";
import goBack from "../../images/svgs/icons/blogGoBack.svg";

const ShowBlog = ({blogData,showProgress}) => {

    const history = useHistory();

    return (
        <div className="showBlogContainer">
            <div className="goBackbtn"><img src={goBack} alt="Go-Back" onClick={() => history.goBack()} /></div>
            <div className="showBlogMain">
                <CircularProgress   sx={{color:"#415AA9",margin:"5em auto",display:(showProgress?"inherit":"none")}} />
                <div className="showBlogTitle">{blogData.title}</div>        
                {/* <img src={`data:image/png;base64,${blogData.thumbnail}`} alt="Thumbnail" style={{display:(showProgress?"none":"inherit")}} /> */}
                <img src={process.env.REACT_APP_API_BASE_URL+"/blogImages/"+blogData.thumbnail} alt="Thumbnail" style={{display:(showProgress?"none":"inherit")}} />
                <div className="showBlogContent" dangerouslySetInnerHTML={{__html: blogData.content}}></div>
            </div>
        </div>
    )
}

export default ShowBlog
