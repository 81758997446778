import React from 'react';
import "./VisionMission.css";

const VisionMission = () => {

    const data = [
        {
            icon: "visiNdMis",
            title: "Vision & Mission",
            content: "PLI aims to help maritime business to go digital using technology and automation to do business with ease.",
        },
        {
            icon: "goals",
            title: "Goal",
            content: "PLI aims to create a new market that generates 10 lack jobs directly and generates jobs 12-15 times indirectly.",
        },
    ]

    return (
        <div className='visMisMain'>
            {
                data.map((item)=>{
                    return (
                        <div key={item.icon} className="visMisItem">
                            <img src={require("../../images/svgs/"+item.icon+".svg").default} alt={item.icon} />
                            <div className="visMisTitle">{item.title}</div>
                            <div className="visMisContent">{item.content}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default VisionMission
