import React from 'react'
import LightBlueBgBtnBanner from '../../component/common/LightBlueBgBtnBanner/LightBlueBgBtnBanner'
import PrivacyPolicy from '../../component/privacyPolicy/PrivacyPolicy'
import "./PrivacyPolicy.css";
import { useEffect, useState, props } from 'react'
import axios from 'axios';
import { useHistory, useParams } from 'react-router';

const PrivacyPolicyScreen = () => {
    const [PrivacyPolicy, SetPrivacyPolicy] = useState({});
    useEffect(() => {
        axios.post(process.env.REACT_APP_API_BASE_URL + "/api/getPrivacyPolicyByID")
        .then((response) => {
            SetPrivacyPolicy(response.data.PrivacyPolicy);
           
        })
        .catch(err => {
           
        });
    }, []);
    return (
        <>
               <LightBlueBgBtnBanner headline={PrivacyPolicy.privacyPolicyHeding} />
            <div className="tNcContentMain" dangerouslySetInnerHTML={{__html: PrivacyPolicy.privacyPolicyContent}} >
           </div>
        </>
    )
}

export default PrivacyPolicyScreen
