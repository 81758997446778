import React from 'react';
import "./PortDesc.css";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import whiteLeftArrow from "../images/svgs/icons/whiteLeftArrow.svg";
import { useEffect } from 'react';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';


const PortDesc = () => {

    const params = useParams();
    const history = useHistory();
    const [portData, setPortData] = useState({});
    const [showProgress, setShowProgress] = useState(true);

    useEffect(() => {
        console.log(params)
        let data = {id:params.portId};
    
        axios.post(process.env.REACT_APP_API_BASE_URL+"/api/port/",data)
        .then((response)=>{
            setPortData(response.data.port);
            setShowProgress(false);
        })
        .catch(err=>{
            if(err.response.status===503)
                history.push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.msg }});
            else{
                history.push({pathname: '/serverError', state: { code: err.response.status, msg:err.response.data.msg }});
            }
        })
    },[]);

    return (
        <div className='portDescContainer'>
            <div className="portDescHeader">
                <div className="portDescSubHeader">
                    <img src={whiteLeftArrow} onClick={() => history.goBack()} alt="go back" />
                    <div className="portDescHeaderDetails">
                        <div className="portDescHeaderTitle">{portData.portName}</div>
                    </div>
                </div>
            </div>
            <CircularProgress   sx={{color:"#415AA9",margin:"5em auto",display:(showProgress?"inherit":"none")}} />
            <div className='portDescMain'>
                <div className="portDescContent" dangerouslySetInnerHTML={{__html: portData.content}}></div>
            </div>
        </div>
    )
}

export default PortDesc
