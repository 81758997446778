import React from 'react';
import "./Pearks.css";
import Container from '@mui/material/Container';

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

const Pearks = () => {

    const pearksData = [
        {
            icon: "shieldCheck",
            title: "Live Loading, Sealed, and Accurate Unloading",
            content: "The video of the live loading would be stored to the cloud and made available to any client. We provide a very secure seal solution for security purposes."
        },
        {
            icon: "locationBlue",
            title: "Tracking Solution",
            content: "Leverage the cost-effective tracking option for logistics and vessels via the portal, without having to spend extra on availing it."
        },
        {
            icon: "billing",
            title: "Billing Automation",
            content: "Subscribed users will be able to automatically make trade agreements through the process and complete it by generating bills and accessing the eWay Billing option."
        },
        {
            icon: "document",
            title: "Quality Assurance Check Report",
            content: "Our data updates and reports are thoroughly revised and verified before publishing. Each report is PLI-verified and has a certificate number verifiable on the site."
        },
    ]

    return (
        <div className="pearksContainer">
            <Container>
                <div className="feature-section-head">
                    We <span>Travel </span>The <span>Extra Mile </span>For You

                    <p>
                        Our suite of on-demand services is catered to help trade with ease
                    </p>
                </div>
                <div className="pearksMain">
                    <Swiper
                        //  modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={3}
                        // navigation
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        breakpoints={{
                            576: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            991: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {
                            pearksData.map((peark, index) => {
                                return (
                                    <SwiperSlide>
                                        <div key={peark.icon} className="pearksItem">
                                            <div className="perks-icon">
                                                <img src={require("../../images/svgs/icons/" + peark.icon + ".svg").default} alt={peark.icon} />
                                            </div>
                                            <div className="pearksTitle">{peark.title}</div>
                                            <div className="pearksContent">{peark.content}</div>
                                        </div></SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </Container>
        </div>
    )
}

export default Pearks
