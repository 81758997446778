import "./ProductListing.css";
import Grid from '@mui/material/Grid';
import gps from "./images/gps.svg";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState, props } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router';


const ProductList = () => {
    const [productList, SetProductList] = useState([]);
    const [formatTitle, setFormatTitle] = useState("");
    const [showProgress, setShowProgress] = useState(true);

    const initialLoad = 9;
    const laterLoad = 3;
    const history = useHistory();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + "/api/allProducts", { initialLoad })
            .then((response) => {
                SetProductList(response.data.productData);

                setShowProgress(false);
            })
            .catch(err => {
                // history.push({ pathname: '/serverError', state: { code: err.response.status, msg: err.response.data.err.message } });
            });
    }, []);
    return (

        <>
            <div className="product-list-container">
                <div className="product-listing-area">
                    <Grid container  >
                        <Grid xs={12} md={4}>
                            <div className="product-listing-header">
                                <div>
                                    <svg width="94" height="100" viewBox="0 0 94 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M31.5874 76.4751H34.9124V79.8001H31.5874V76.4751Z" fill="#415AA9" />
                                        <path d="M44.8875 63.175H48.2125V66.5H44.8875V63.175Z" fill="#415AA9" />
                                        <path d="M51.5376 63.175H54.8626V66.5H51.5376V63.175Z" fill="#415AA9" />
                                        <path d="M38.2375 63.175H41.5625V66.5H38.2375V63.175Z" fill="#415AA9" />
                                        <path d="M58.1875 76.4751H61.5125V79.8001H58.1875V76.4751Z" fill="#415AA9" />
                                        <path d="M38.2375 76.4751H54.8625V79.8001H38.2375V76.4751Z" fill="#415AA9" />
                                        <path d="M88.1125 9.975H73.15V1.6625C73.15 0.745161 72.4048 0 71.4875 0H21.6125C20.6952 0 19.95 0.745161 19.95 1.6625V9.975H4.9875C2.23763 9.975 0 12.2126 0 14.9625V81.4625C0 84.2124 2.23763 86.45 4.9875 86.45H38.2375V96.425H33.25V99.75H59.85V96.425H54.8625V86.45H88.1125C90.8624 86.45 93.1 84.2124 93.1 81.4625V14.9625C93.1 12.2126 90.8624 9.975 88.1125 9.975ZM88.1125 13.3C89.0284 13.3 89.775 14.0466 89.775 14.9625V69.825H86.45V18.2875C86.45 17.3702 85.7048 16.625 84.7875 16.625H73.15V13.3H88.1125ZM21.6125 53.2H71.4875C72.4048 53.2 73.15 52.4548 73.15 51.5375V19.95H83.125V69.825H9.975V19.95H19.95V51.5375C19.95 52.4548 20.6952 53.2 21.6125 53.2ZM43.225 16.625H49.875V31.805L47.4725 30.2041C47.1934 30.0179 46.8705 29.9251 46.5499 29.9251C46.2293 29.9251 45.9064 30.0178 45.6274 30.2041L43.2248 31.805L43.225 16.625ZM39.9 16.625V34.9125C39.9 35.5263 40.2377 36.0896 40.778 36.3791C41.3168 36.667 41.9737 36.6351 42.4836 36.2959L46.5499 33.5854L50.615 36.2952C50.8941 36.4822 51.2147 36.575 51.5375 36.575C51.807 36.575 52.0764 36.5104 52.322 36.3791C52.8624 36.0896 53.2 35.5263 53.2 34.9125V16.625H69.825V49.875H23.275V16.625H39.9ZM69.825 11.6375V13.3H54.8625V3.325H69.825V11.6375ZM51.5375 3.325V13.3H41.5625V3.325H51.5375ZM23.275 3.325H38.2375V13.3H23.275V3.325ZM4.9875 13.3H19.95V16.625H8.3125C7.39516 16.625 6.65 17.3702 6.65 18.2875V69.825H3.325V14.9625C3.325 14.0466 4.07164 13.3 4.9875 13.3ZM51.5375 96.425H41.5625V86.45H51.5375V96.425ZM89.775 81.4625C89.775 82.3784 89.0284 83.125 88.1125 83.125H4.9875C4.07164 83.125 3.325 82.3784 3.325 81.4625V73.15H89.775V81.4625Z" fill="#415AA9" />
                                        <path d="M63.175 39.8999H66.5V46.5499H63.175V39.8999Z" fill="#415AA9" />
                                        <path d="M56.5249 39.8999H59.8499V46.5499H56.5249V39.8999Z" fill="#415AA9" />
                                        <path d="M26.6001 6.6499H29.9251V9.9749H26.6001V6.6499Z" fill="#415AA9" />
                                        <path d="M63.175 6.6499H66.5V9.9749H63.175V6.6499Z" fill="#415AA9" />
                                        <path d="M34.9126 56.5249H58.1876V59.8499H34.9126V56.5249Z" fill="#415AA9" />
                                    </svg>
                                    <div className='list-title'>
                                        Our Products
                                    </div>
                                    <p className='list-header-detail'>
                                    PORT LINKS INDIA provides device/products according on your specifications. We provide a wide range of products that will meet all of your requirements in one place.

                                    </p>
                                    <p className='list-header-detail'>
                                    We are your digital transformation technology partner. We are a software development and hardware integration firm with professional experience in hardware development and software development.

                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} md={8}>
                            <div className="product-listing-detail-section">
                                <Grid container m={0}>
                                    {productList.map((data, Idx) => {
                                        return (
                                            <Grid xs={12} md={6} >
                                                <Link
                                                    to={{
                                                        pathname: `/product-details/${data._id}`,
                                                        state: { data },
                                                    }}
                                                >
                                                    {/* <Link to={history.push({pathname: '/product-details', state: { code: data._id }})} > */}
                                                    <div className="product-list-detail-card">
                                                        <div className="product-list-detail-card-title">
                                                            {/* <img src="images/" alt="" /> */}
                                                            <div className='product-card-img'>
                                                                <img src={process.env.REACT_APP_API_BASE_URL + "/productImages/" + data.productIcon} alt={data.productIcon}
                                                                // style={{ display: (showProgress ? "none" : "inherit") }} 
                                                                />

                                                            </div>
                                                            <div className='title'>
                                                                {data.productName}

                                                            </div>
                                                        </div>
                                                        <div className="product-list-detail-card-body">
                                                            {data.productDescription.length > 100 ? (data.productDescription.substring(0, 100) + " ...") : data.productDescription}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        </Grid>


                    </Grid>
                </div>
            </div>
        </>
    )

}

export default ProductList
