import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import "./AllBlogs.css";

import BlogOver from "../../common/BlogOver/BlogOver";
import noBlogs from "../../images/svgs/noBlogs.svg";
import { useHistory } from "react-router";

const AllBlogs = ({setLoadMore, loadShowProgress, showProgress, shownData, showLoadMoreBtn}) => {

    const history = useHistory();

    return (
        <>
            <CircularProgress  sx={{color:"#415AA9",margin:"5em auto",display:(showProgress?"inherit":"none")}} />
            {
                shownData.length===0
                ? showProgress
                    ? <div></div>
                    : <div className="noBlogFound" >
                        <img src={noBlogs} alt="No Blogs" />
                        <div className="noBlogFoundMsg" >Oops!! Looks like no blogs are added yet</div>
                        <div className="bacKToHomeBtn" onClick={()=>history.push("/")}>Back To Home</div>
                    </div>
                :
                <>
                    <div className="allBlogsContainer">
                    {
                        shownData.map((blogData)=>{
                            return(
                                <BlogOver key={blogData.id} blogData={blogData} />
                            )
                        })
                    }
                    </div>
                    <CircularProgress  sx={{color:"#415AA9",margin:"5em auto",display:(loadShowProgress?"inherit":"none")}} />
                    <div className="allBlogsLoadMore" style={{display:(showLoadMoreBtn?"inherit":"none")}} onClick={()=>setLoadMore(prev=>prev+1)}>Load More</div>
                </>
            }
        </>
    )
}

export default AllBlogs
