import React from 'react';
import "./BlogNavbar.css";
import blueSearch from "../../images/svgs/icons/blueSearch.svg";
// import blueSort from "../../images/svgs/icons/blueSort.svg";
import blueFilter from "../../images/svgs/icons/blueFilter.svg";
import { useState, useEffect, useRef } from 'react';

const BlogNavbar = ({filter, setFilter, search, setSearch, sort, setSort}) => {

    const filterData = ["All","Coal","Salt","Timber"];
    // const sortData = [
    //     {
    //         tag:"Sort By Name",
    //         key:"name"
    //     },
    //     {
    //         tag:"Sort By Date",
    //         key:"date"
    //     }
    // ];

    // const [openSort, setOpenSort] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const searchRef = useRef();
    const sortRef = useRef();
    const filterRef = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if ( 
                sortRef.current && !sortRef.current.contains(event.target) && 
                filterRef.current && !filterRef.current.contains(event.target)
            ) {
                // setOpenSort(false);
                setOpenFilter(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sortRef,filterRef]);

    
    return (
        <>
            <div className="blogNavbarContainer">
                <div className="navbarWrapper">
                    <div className="searchBar">
                        <img ref={searchRef} src={blueSearch} alt="search"  onClick={()=>{
                            setOpenSearch(!openSearch);
                            // setOpenSort(false);
                            setOpenFilter(false);
                        }} />
                        <input type="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search here" />
                    </div>

                    <div className="rightOps">
                        {/* <div className="dropDown" ref={sortRef}>
                            <div className="dropDownBtn" onClick={()=>{
                                setOpenSort(!openSort);
                                setOpenFilter(false);
                            }}>
                                <img src={blueSort} alt="Select" />
                            </div>
                            <div className={"dropDownOptions "+(openSort?"openDropDown":"")}>
                                {
                                    sortData.map((data,index)=>{
                                        return(
                                            <div className={"dropDownOptionItem removeSelection "+(sort===data.key?"selectedOption":"")} 
                                                onClick={()=>{
                                                    setSort(data.key);
                                                    setOpenSort(false);
                                                }} >
                                                {data.tag}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
                        <div className="dropDown" ref={filterRef}>
                            <div className="dropDownBtn" onClick={()=>{
                                setOpenFilter(!openFilter);
                                // setOpenSort(false);
                            }} >
                                <img src={blueFilter} alt="Select" />
                            </div>
                            <div className={"dropDownOptions "+(openFilter?"openDropDown":"")}>
                                {
                                    filterData.map((data,index)=>{
                                        return(
                                            <div className={"dropDownOptionItem removeSelection "+(filter===data?"selectedOption":"")} 
                                                onClick={()=>{
                                                    setFilter(data);
                                                    setOpenFilter(false);
                                                }} >
                                                {data}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabletSearchBlogWrapper">
                <input className={"tabletBlogSearch "+(openSearch?"showTabletBlogSearchbar":"")} 
                        type="text" 
                        value={search} 
                        onChange={(e)=>setSearch(e.target.value)} 
                        placeholder="Search here" 
                />
            </div>
        </>
    )
}

export default BlogNavbar
