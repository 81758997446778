import React from 'react'
import "./ServiceListing.css";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import gpsSoftware from "./images/gps-software.svg";
import { Link } from "react-router-dom";
import{ useEffect, useState, props } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router';

const ServiceList = () => {
    const [serviceList, SetServicetList] = useState([]);
    const [formatTitle, setFormatTitle] = useState("");
    const [showProgress, setShowProgress] = useState(true);

    const initialLoad = 9;
    const laterLoad = 3;
    const history = useHistory();
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + "/api/allServices", { initialLoad })
            .then((response) => {
                SetServicetList(response.data.servicesData);

                setShowProgress(false);
            })
            .catch(err => {
                // history.push({ pathname: '/serverError', state: { code: err.response.status, msg: err.response.data.err.message } });
            });
    }, []);
    return (

        <>
            <div className="service-list-container">
                <div className="service-listing-area">
                    <div className="service-listing-header">
                        Services We Provide
                    </div>
                    <div className="service-listing-detail-section">
                        <Container>

                            <Grid container spacing={2} m={0}>
                                {serviceList.map((data, Idx) => {
                                    return (
                                        <Grid xs={12} md={4}>
                                            <Link  to={{
                                                        pathname: `/service-details/${data._id}`,
                                                        state: { data },
                                                    }}
                                                >
                                                <div className="service-list-detail-card">
                                                    <div className="service-list-detail-card-title">
                                                        {/* <img src="images/" alt="" /> */}
                                                        <div className='service-card-img'>
                                                            <img src={process.env.REACT_APP_API_BASE_URL + "/servicesImages/" + data.servicesIcon} alt={data.servicesIcon}
                                                                // style={{ display: (showProgress ? "none" : "inherit") }}
                                                            />
                                                        </div>
                                                        <div className='title'>
                                                            {data.servicesName}

                                                        </div>
                                                    </div>
                                                    <div className="service-list-detail-card-body">
                                                        {data.servicesDescription.length > 100 ? (data.servicesDescription.substring(0, 100) + " ...") : data.servicesDescription}
                                                    </div>
                                                </div>
                                            </Link>
                                        </Grid>
                                    );
                                })}

                            </Grid>
                        </Container>
                    </div>

                </div>
            </div>
        </>
    )

}

export default ServiceList
