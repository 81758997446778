import React from 'react'
import { Link } from 'react-router-dom';

const BenefitComp = ({benefit,row}) => {
    return (
        <>
        <div className={"benefitItem "+(row?"rowBenefitItem":"rowReverseBenefitItem")}>
            <div className="benefitDetail">
                <div className="benefitHead">{benefit.head}</div>
                <div className="benefitContent"> {benefit.content} </div>
            </div>
            <div className="benefitSvg">
                <img src={require("../../images/svgs/"+benefit.image).default} alt={benefit.image} />
            </div>
        </div>
        </>
    )
}

export default BenefitComp
