import React from 'react';
import "./AboutSection.css";

const AboutSection = () => {

    const aboutSectionData = [
        {
            image:"shipFront.png",
            headline:"Maritime Industry is the fundamental part of the world trade",
            content:"We depend on maritime shipping for the transport of raw materials like coal, food, medicines, and finished products. Since trade and commerce heavily depend on maritime shipping, it can be a significant economic driver. Why is then, the marine trade in India presently accounting for just 6% of the national economy?"
        },
        {
            image:"planMaking.png",
            headline:"Problems with Legacy Business Model",
            content:"It’s been 700 years since India has stepped into marine trade, but the business practices have not evolved as such. Business users can’t get the latest updates of the vessels and available products. Buyers suffer from a lack of communication and the logistics industry from lack of knowledge and inconvenience. This traditional concept of trading has to change."
        },
        {
            image:"shitTop.png",
            headline:"We entrust ourselves to improve the Maritime Business.",
            content:"Port Links India is committed to innovating and evolving upon the legacy business model by enabling the highest user experience and bringing all stakeholders associated with the business under one roof."
        }
    ];

    var row = false;

    return (
        <div className="aboutSectionContainer">
            {
                aboutSectionData.map((data,index)=>{
                    row = !row;
                    return (
                        <div className={"aboutSectionItem "+(row?"rowAboutItemdata":"rowReverseAboutItemdata")}>
                            <div className="imageContainer">
                                <img src={require("../../images/pngs/"+data.image).default} alt={data.image} />
                            </div>
                            <div className="aboutSectionItemDetail">
                                <div className="aboutSectionItemHead">{data.headline}</div>
                                <div className="aboutSectionItemContent">{data.content}</div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default AboutSection
