import React from 'react';
import { useHistory } from 'react-router';

import "./ErrorComp.css";

const ErrorComp = ({errorData}) => {

    const history = useHistory();

    return (
        <div className="notFoundCard">
            <div className="errCode">{errorData.code}</div>

            <div className="errImg">
                <img src={require("../../images/svgs/"+errorData.img+".svg").default} className="img-fluid" alt="Not Found" />
            </div>

            <div className="errMsg">Oops!! Looks like something went wrong</div>

            <div className="goHomeBtn" onClick={()=>history.push("/")}>Back To Home</div>
        </div>
    )
}

export default ErrorComp
