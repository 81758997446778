import React from 'react';
import ErrorComp from '../../component/common/ErrorComp/ErrorComp';

const NotFound = () => {

    const errorData = {
        code: 404,
        msg: "Sorry, page not found",
        img: "404"
    }

    return (
        <>
            <ErrorComp errorData={errorData} />
        </>
    )
}

export default NotFound
