import React from 'react'
import AboutSection from '../../component/about/AboutSection/AboutSection'
import LandingAboutComp from '../../component/about/LandingAboutComp/LandingAboutComp'
import LightBlueBgAboutBanner from '../../component/about/LightBlueBgAboutBanner/LightBlueBgAboutBanner'
import VisionMission from '../../component/about/VisionMission/VisionMission'
import GetStarted from '../../component/common/GetStarted/GetStarted'

const AboutScreen = () => {

    const getStartedData = {
        title:"Get Started with our Portal",
        subTitle:"Signup on our portal and increase your business",
        btn:"Join Now",
        link: "https://login.portlinksindia.com/"
    }

    return (
        <>
            <LandingAboutComp />
            <LightBlueBgAboutBanner />
            <AboutSection />
            <VisionMission />
            <GetStarted getStartedData={getStartedData} />
        </>
    )
}

export default AboutScreen
