import React from 'react'
import AllCareer from '../../component/career/AllCareer/AllCareer'

const AllCareerScreen = () => {
    return (
        <>
            <AllCareer />
        </>
    )
}

export default AllCareerScreen