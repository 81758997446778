import React from 'react';
import CoalCalc from '../../component/commodities/coal/CoalCalc/CoalCalc';
import GetStarted from '../../component/common/GetStarted/GetStarted';
import CoalIndex from "../../component/commodities/coal/CoalIndex/CoalIndex"
import LightBlueBgBanner from '../../component/common/LightBlueBgBanner/LightBlueBgBanner'

const CoalScreen = () => {

    const getStartedData = {
        title:"Get Started with our Portal",
        subTitle:"Signup on our portal and increase your business",
        btn:"Join Now",
        link: "https://login.portlinksindia.com/"
    }

    return (
        <div>
            <CoalIndex />
            <CoalCalc />
            <GetStarted getStartedData={getStartedData} />
        </div>
    )
}

export default CoalScreen
