import React from 'react';
import { useHistory } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import "./ShowNews.css";
import goBack from "../../images/svgs/icons/blogGoBack.svg";

const ShowNews = ({ newsData, showProgress }) => {

    const history = useHistory();

    return (
        <div className="showNewsContainer">
            <div className="goBackbtn"><img src={goBack} alt="Go-Back" onClick={() => history.goBack()} /></div>

                <div className="showNewsMain">
                    <CircularProgress   sx={{color:"#415AA9",margin:"5em auto",display:(showProgress?"inherit":"none")}} />
                        <div className="showNewsTitle">{newsData.title}</div>        
                        {/* <img src={`data:image/png;base64,${blogData.thumbnail}`} alt="Thumbnail" style={{display:(showProgress?"none":"inherit")}} /> */}
                        <img src={process.env.REACT_APP_API_BASE_URL+"/newsImages/"+newsData.thumbnail} alt="Thumbnail" style={{display:(showProgress?"none":"inherit")}} />
                        <div className="showNewsContent" dangerouslySetInnerHTML={{__html: newsData.content}}></div>
                </div>
            </div>

        //                 <div
        //                     style={{
        //                         border: "none",
        //                         borderBottom: "solid windowtext 1.5pt",
        //                         padding: "0cm 0cm 1.0pt 0cm"
        //                     }}
        //                     __rte_selected_block=""
        //                 >
        //                     {" "}
        //                     <p style={{ border: "none" }}> </p> <p style={{ border: "none" }}>&nbsp;</p>{" "}
        //                     {/* <br clear="ALLA" />{" "} */}
        //                     <p
        //                         align="center"
        //                         style={{
        //                             marginBottom: ".0001pt",
        //                             textAlign: "center",
        //                             lineHeight: "normal",
        //                             border: "none"
        //                         }}
        //                     >
        //                         <b>
        //                             <span
        //                                 style={{ fontSize: "20.0pt", fontFamily: '"Tw Cen MT",sans-serif' }}
        //                             >
        //                                 DEENDAYAL PORT AUTHORITY, KANDLA
        //                             </span>
        //                         </b>
        //                     </p>{" "}
        //                     <p
        //                         align="center"
        //                         style={{
        //                             marginBottom: ".0001pt",
        //                             textAlign: "center",
        //                             lineHeight: "normal",
        //                             border: "none"
        //                         }}
        //                     >
        //                         <b>
        //                             <span
        //                                 style={{ fontSize: "20.0pt", fontFamily: '"Tw Cen MT",sans-serif' }}
        //                             >
        //                                 (INDIA’S NO. 1 MAJOR PORT)
        //                             </span>
        //                         </b>
        //                     </p>{" "}
        //                 </div>{" "}
        //                 <p
        //                     style={{
        //                         marginRight: "-23.6pt",
        //                         marginLeft: "-21.3pt",
        //                         marginBottom: ".0001pt",
        //                         textIndent: "21.3pt",
        //                         lineHeight: "normal"
        //                     }}
        //                 >
        //                     <span style={{ fontSize: "14.0pt", fontFamily: '"Cambria",serif' }}>
        //                         <span>
        //                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //                         </span>
        //                     </span>
        //                     <b>
        //                         <span style={{ fontSize: "12.0pt", fontFamily: '"Verdana",sans-serif' }}>
        //                             Date: 29-01-2023
        //                         </span>
        //                     </b>
        //                 </p>{" "}
        //                 <p
        //                     align="center"
        //                     style={{
        //                         marginLeft: "-21.3pt",
        //                         marginBottom: ".0001pt",
        //                         textAlign: "center",
        //                         textIndent: "21.3pt",
        //                         lineHeight: "normal"
        //                     }}
        //                 >
        //                     <b>
        //                         <u>
        //                             <span style={{ fontSize: "1.0pt", fontFamily: '"Verdana",sans-serif' }}>
        //                                 <span style={{ textDecoration: "none" }}>&nbsp;</span>
        //                             </span>
        //                         </u>
        //                     </b>
        //                 </p>{" "}
        //                 <p
        //                     align="center"
        //                     style={{
        //                         marginLeft: "-21.3pt",
        //                         textAlign: "center",
        //                         textIndent: "21.3pt",
        //                         lineHeight: "115%"
        //                     }}
        //                 >
        //                     <b>
        //                         <u>
        //                             <span
        //                                 style={{
        //                                     fontSize: "14.0pt",
        //                                     lineHeight: "115%",
        //                                     fontFamily: '"Verdana",sans-serif'
        //                                 }}
        //                             >
        //                                 Press Release
        //                             </span>
        //                         </u>
        //                     </b>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", textIndent: "1.0cm", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         The Deendayal Port Authority, No.1 Major Port of the Nation, has embarked
        //                         upon to develop a ‘State of Art’ Mega Container Terminal at Tuna Tekra,
        //                         Kandla, under PPP mode, for a concession period of 30 years, through
        //                         global competitive bidding process, to be developed in the adjacent East
        //                         side of existing Dry Bulk Terminal, currently being operated by AKBTPL.
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", textIndent: "1.0cm", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         M/s. Hindustan Infralog Pvt. Ltd. (D P World) has emerged as the Highest
        //                         Bidder for becoming the ‘Concessionaire’ of the subject project, by
        //                         offering ‘Royalty’ of Rs.6500/- per TEU. This is also the highest bid ever
        //                         received in a PPP project.{" "}
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", textIndent: "1.0cm", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         This Project, which was conceived in the year 2013 is now going to see the
        //                         light of the day.
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", textIndent: "1.0cm", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         The Project entails an investment of about Rs 4500 Cr, which is the
        //                         highest capital investment ever in a PPP project at any of the Major ports
        //                         of the India.<span style={{ color: "red" }}> </span>
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", textIndent: "1.0cm", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         This Mega Container Terminal Project is envisaged for handling capacity of
        //                         2.19 Million TEUs per annum, with Estimated Project Cost of Rs.4243.64
        //                         Crores for Concessionaire and Rs.296.20 Crores for Authority. DPA will
        //                         invest in the common basic infrastructure viz Access Channel for
        //                         navigation of vessels and Road. The project facility shall cater the
        //                         container vessels of size upto 21000 TEUs, with a draught of 18 M, without
        //                         any pre-berthing detention for want of the tide. <span>&nbsp;</span>The
        //                         operations of the Terminal is expected to start in early 2026.{" "}
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", textIndent: "1.0cm", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         The importance of this project can be realized from the fact that this
        //                         project is a part of Hon’ble Prime Minister’s vision ‘Sagarmala’ and ‘PM
        //                         Gati Shakti National Master Plan’ and implementation of the project is
        //                         being monitored by the PMO. Therefore, the project was structured and
        //                         global publicity was adopted under the guidance of Ministry of Ports,
        //                         Shipping and Waterways, wherein mega Road Show was also organized at
        //                         Mumbai, in order to successfully conclude the bidding process. The project
        //                         has already been apprised by the PPPAC and approved by the Union Cabinet,
        //                         GoI. Also, MoEF&amp;CC has granted the Environment Clearance to the
        //                         project.
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", textIndent: "1.0cm", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         The successful implementation of the project shall not only bring a new
        //                         era of Mega Container handling at Deendayal Port at Kandla, but also have
        //                         a huge positive impact on economical &amp; social scenario of the Kutch
        //                         district and Gujarat region at large.{" "}
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", textIndent: "1.0cm", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         The project will entail the following benefits
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         1) Due to its strategic location (closest among all ports- Major or minor,
        //                         to the densely populated and fast developing northern hinterland), the
        //                         Container Terminal <span>&nbsp;</span>will help in decreasing the cost of
        //                         container logistics in the country
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         2) with deep draft and latest handling technology, the Container Terminal
        //                         is expected to set up a new benchmark in productivity and ease of doing
        //                         business
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         3) the Container Terminal is expected to transform the economic landscape
        //                         of Kutchh, with creation of several ancillary services ( warehousing etc)
        //                         and direct and indirect employment opportunities to millions of people.
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         4)The Container Terminal, apart from earning royalties for DPA will also
        //                         be a buoyant source of taxation income ( direct and Indirect ) to the GOI
        //                     </span>
        //                 </p>{" "}
        //                 <p style={{ textAlign: "justify", lineHeight: "115%" }}>
        //                     <span
        //                         style={{
        //                             fontSize: "12.0pt",
        //                             lineHeight: "115%",
        //                             fontFamily: '"Verdana",sans-serif'
        //                         }}
        //                     >
        //                         5) The Container Terminal is also likely to give a major fillip to
        //                         infrastructure development in Gujarat with major investments needed from
        //                         NHAI, and Railways. These are likely to help the development of other
        //                         parts of the state as well, apart from Kutch.{" "}
        //                     </span>
        //                 </p>{" "}
        //                 <p>
        //                     <br />
        //                 </p>
        //             </div>

        //         </div>
        //     </div>
        // </div>
    )
}

export default ShowNews
