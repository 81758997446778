import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { CircularProgress, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import minorShape from '../../component/images/svgs/round-shape.svg';
import portImg from '../../component/images/pngs/planMaking.png';
import { compose, withProps } from "recompose";
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
} = require("react-google-maps");

const MyMapComponent = compose(
    withProps({
        /**
         * Note: create and replace your own key in the Google console.
         * https://console.developers.google.com/apis/dashboard
         * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
         */
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: 23.011040, lng: 70.211646 }}>
        {props.isMarkerShown && (
            <Marker position={{ lat: 23.011040, lng: 70.211646 }} />
        )}
    </GoogleMap>
));
const Weather = (props) => {

    const defaultProps = {
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4&v=3.exp&libraries=geometry,drawing,places",
    }

    const [weatherList, setWeatherList] = useState([]);
    const [showProgress, setShowProgress] = useState(false);
    const [filterType, setFilterType] = useState('30 MIN');
    const [latitude, setLattitude] = useState(23.011040);
    const [longitude, setLongitude] = useState(70.211646);
    const [activeMarker, setActiveMarker] = useState(false);

    useEffect(() => {
        getWeatherList('30 MIN')
    }, []);

    const getWeatherList = (filterType) => {
        try {
            const data = {
                FilterType: filterType ? filterType : '30 MIN'
            };
            axios
                .post(
                    `https://uat.portlinksindia.com:3005/mqttapi/GetWeatherList`,
                    data
                )
                .then((response) => {
                    if (response?.data?.success) {
                        setWeatherList([...response?.data?.data]);
                    }
                })
                .catch((err) => {
                    console.log("Error - getImportExport: ", err);
                });
        } catch (error) {
            console.log("Error Catch - getImportExport: ", error);
        }
    };

    return (
        <div className="coalIndexContainer" /*ref={tableRef}*/>
            <div className="coalIndexHeading">Weather</div>
            <div className="coalIndexTable">
                <div className="coalIndexTableHeader">
                    <CircularProgress sx={{ color: "#415AA9", margin: "5em auto", display: (showProgress ? "inherit" : "none") }} />
                    <ToggleButtonGroup
                        color="primary"
                        value={filterType}
                        exclusive
                        onChange={(e, value) => {
                            setFilterType(value);
                            getWeatherList(value)
                        }}
                        aria-label="Filter Type"
                    >
                        <ToggleButton value="30 MIN">30 MIN</ToggleButton>
                        <ToggleButton value="24 HRS">24 HRS</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <label>{filterType ? filterType : " "} {' '} Data</label>
                <div className="allBlogsContainer">
                    <div className="blogOverContainer">
                        <div className="blogDetails">
                            <div className="blogTitle">Humidity (%)</div>
                            <div className="blogCommodity">{weatherList?.length && weatherList[0][0]?.hum}</div>
                        </div>
                    </div>
                    <div className="blogOverContainer">
                        <div className="blogDetails">
                            <div className="blogTitle">Pressure(hPa)
                            </div>
                            <div className="blogCommodity">{weatherList?.length && weatherList[0][0]?.pr}</div>
                        </div>
                    </div>
                    <div className="blogOverContainer">
                        <div className="blogDetails">
                            <div className="blogTitle">Rain ( In. )</div>
                            <div className="blogCommodity">{weatherList?.length && weatherList[0][0]?.rain}</div>
                        </div>
                    </div>
                    <div className="blogOverContainer">
                        <div className="blogDetails">
                            <div className="blogTitle">Temperature ( °C )</div>
                            <div className="blogCommodity">{weatherList?.length && weatherList[0][0]?.temp}</div>
                        </div>
                    </div>

                </div>
                <br />
                <hr />
                <label> Latest Data</label>
                <div className="allBlogsContainer">
                    <div className="blogOverContainer">
                        <div className="blogDetails">
                            <div className="blogTitle">Humidity (%)</div>
                            <div className="blogCommodity">{weatherList?.length && weatherList[1][0]?.hum}</div>
                        </div>
                    </div>
                    <div className="blogOverContainer">
                        <div className="blogDetails">
                            <div className="blogTitle">Pressure
                                (h
                                Pa)
                            </div>
                            <div className="blogCommodity">{weatherList?.length && weatherList[1][0]?.pr}</div>
                        </div>
                    </div>
                    <div className="blogOverContainer">
                        <div className="blogDetails">
                            <div className="blogTitle">Rain ( mm )</div>
                            <div className="blogCommodity">{weatherList?.length && weatherList[1][0]?.rain}</div>
                        </div>
                    </div>
                    <div className="blogOverContainer">
                        <div className="blogDetails">
                            <div className="blogTitle">Temperature ( °C )</div>
                            <div className="blogCommodity">{weatherList?.length && weatherList[1][0]?.temp}</div>
                        </div>
                    </div>

                </div>

            </div>
            <Grid className="coalIndexTable" >
                <Grid xs={10} md={6}>

                    <div className="map-area">
                        <MyMapComponent isMarkerShown />
                    </div>

                </Grid>

            </Grid>
        </div>


    )
}

export default Weather
