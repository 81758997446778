import React, { useEffect, useState } from 'react';
import "./Pricing.css";
import { modulesByUserData, modulesByUserDataS, modulesData, pricingData } from './pricingData';

import doneCircleBlueFilled from "../../images/svgs/icons/doneCircleBlueFilled.svg";
import doneCircleGrayFilled from "../../images/svgs/icons/doneCircleGrayFilled.svg";
import dropDownIcon from "../../images/svgs/icons/grayCaretDown.svg";
import { useRef } from 'react';

const Pricing = () => {

    const [user, setUser] = useState ("importer");
    const [userName, setUserName] = useState("Importer");

    // const [givenModule, setGivenModule] = useState([]);
    // const [notGivenModule, setNotGivenModule] = useState([]);
    const [slicedModuleData, setSlicedModuleData] = useState([]);

    const [allowed, setAllowed] = useState(0);
    const [open, setOpen] = useState(false);
    const dropDownRef = useRef();

    const [price, setPrice] = useState(12000);

    useEffect(() => {
        // const given = [];
        // const notGiven = [];

        // const maxCount = user==="handler"? 19 : 15;
        
        // for(var i=0; i<maxCount; i++){
        //     if(modulesByUserData[user][i]===true)
        //         given.push({
        //             module:modulesData[i],
        //             given:true
        //         });
        //     else
        //         notGiven.push({
        //             module:modulesData[i],
        //             given:false
        //         });
        // }

        setSlicedModuleData(modulesByUserDataS[user].data);

        setAllowed(modulesByUserDataS[user].allowed);

    }, [user]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRef]);

    return (
        <>
            <div className="pricingContainer">
                <div className="pricingLeft">
                    <div className="entityTypeOptionContainer">
                        <div className="entityTypeOptionHead">Choose Entity Type</div>
                        <div className="entityTypeOptions">
                            {
                                pricingData.map((data,index)=>{
                                    return(
                                        <label key={index}>
                                            <input type="radio" name="user" value={data.key} onChange={(e=>setUser(e.target.value))}  />
                                            <div className={"entityTypeOptionsItem "+(user===data.key?"entityTypeOptionSelected":"")}>
                                                <div className="entityTypeOptionItemUser">{data.user}</div>
                                                <div className="entityTypeOptionItemPrice"><b>₹{data.price}</b>/yr</div>
                                            </div>
                                        </label>
                                    )
                                })
                            }
                        </div>
                        <div ref={dropDownRef} className="userDropDown">
                            <div className="dropDownBtn" onClick={()=>setOpen(!open)}>
                                {userName}
                                <img src={dropDownIcon} alt="Select" style={{transform:(open?"rotate(180deg)":"")}} />
                            </div>
                            <div className={"dropDownOptions "+(open?"openDropDown":"")}>
                                {
                                    pricingData.map((data,index)=>{
                                        return(
                                            <div className={"dropDownOptionItem removeSelection "+(user===data.key?"selectedOption":"")} 
                                                onClick={()=>{
                                                    setUser(data.key);
                                                    setUserName(data.user);
                                                    setPrice(data.price);
                                                    setOpen(false);
                                                }} >
                                                {data.user}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="includedModules">
                    <div className="includedModulesHead">Modules Included</div>
                    <div className="includedModulesContainer">
                        <div className="left">
                            {
                                slicedModuleData.slice(0,7).map((data,index)=>{
                                    return(
                                        <div className="includedModulesItem">
                                            <img src={(index+1<=allowed)?doneCircleBlueFilled:doneCircleGrayFilled} alt="Given" />
                                            {data}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="right">
                            {
                                slicedModuleData.slice(7,14).map((data,index)=>{
                                    return(
                                        <div className="includedModulesItem">
                                            <img src={(index+7+1<=allowed)?doneCircleBlueFilled:doneCircleGrayFilled} alt="Given" />
                                            {data}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="bottomPrices"><b>₹{price}</b>/yr</div>
            </div>
            <a target="_blank" rel="noreferrer" href="https://login.portlinksindia.com/">
                <div className="getStartedButton">Get Started</div>
            </a>
        </>
)
}

export default Pricing
