import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../Footer/Footer.css";

import axios from "axios";
import validator from 'validator';

import PILLogo from "../../images/svgs/pilWhiteLogo2.svg";
import caretRightIcon from "../../images/svgs/icons/caretRight.svg";
import phoneIcon from "../../images/svgs/icons/phone.svg";
import emailIcon from "../../images/svgs/icons/email.svg";
import locationIcon from "../../images/svgs/icons/location.svg";
import linkedInIcon from "../../images/svgs/icons/linkedIn.svg";
import facebookIcon from "../../images/svgs/icons/facebook.svg";
import twitterIcon from "../../images/svgs/icons/twitter.svg";
import instagramIcon from "../../images/svgs/icons/instagram.svg";
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Footer = () => {

    const [newsLetterEmail, setNewsLetterEmail] = useState("");
    const [emailResponse, setEmailResponse] = useState("");
    const [emailResMsg, setEmailResMsg] = useState("");
    const [fullName, setfullName] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [descriptions, setdescriptions] = useState("");


    const emailResponseStyle = {
        textAlign: "left",
        display: (emailResponse === "" ? "none" : "inherit"),
        color: (emailResponse === "success" ? "#F1F2F7" : "#EB5757"),
        transition: "all 0.2s",
        marginTop: "5px",
        fontSize: "12px",
        lineHeight: "20px"
    }
const sentUserEmail =(ID) => 
{axios.post(process.env.REACT_APP_API_BASE_URL + "/api/sentUserEmail", {email:ID})
.then(response => { }) }
    const emailSubscribe = (e) => {
        e.preventDefault();

        if (validator.isEmail(newsLetterEmail)) {
            const emailData = { email: newsLetterEmail, descriptions: descriptions, phoneNumber: phoneNumber, fullName: fullName };
            axios.post(process.env.REACT_APP_API_BASE_URL + "/api/subscribeEmail", emailData)
                .then(response => {debugger
                    if (response.status === 200) {
                        
                        setEmailResponse("success");
                        sentUserEmail(newsLetterEmail);
                        setEmailResMsg("You will be updated with our latest content");
                        setNewsLetterEmail("");
                        setfullName('');
                        setphoneNumber('');
                        setdescriptions('');
                        setTimeout(() => {
                            setEmailResponse("");
                            setEmailResMsg("");

                        }, 3000);

                    } else {
                        setEmailResMsg("Some Error Occured !!");
                        setEmailResponse("error");

                        setTimeout(() => {
                            setEmailResponse("");
                            setEmailResMsg("");
                        }, 3000);
                    }
                })
                .catch(err => {
                    setEmailResponse("error");
                    setEmailResMsg("Given Email is already present");

                    setTimeout(() => {
                        setEmailResponse("");
                        setEmailResMsg("");
                    }, 3000);
                })

        }
        else {
            setEmailResponse("error");
            setEmailResMsg("Please Enter Valid Email");

            setTimeout(() => {
                setEmailResponse("");
                setEmailResMsg("");
            }, 3000);
        }
    }

    return (
        <div className="footerContainer" >
            <Grid container  >
                <Grid xs={12} sm={12} md={9}>
                    <div className='footer-header'>
                        <div className="footerPILWhiteLogo" >
                            <img src={PILLogo} alt="PIL" />
                        </div>
                        <div className="footerBottom">
                            <div className="footerSocialMedia">
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/port-links-india-04705921b/"><img src={linkedInIcon} alt="LinkedIn" /></a>
                                <a target="_blank" rel="noreferrer" href="https://m.facebook.com/profile.php?id=100072010910900"><img src={facebookIcon} alt="Facebook" /></a>
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/PortLinksIndia"><img src={twitterIcon} alt="Twitter" /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/portlinksindia/"><img src={instagramIcon} alt="Instagram" /></a>
                            </div></div>
                    </div>
                    <hr />

                    <div className="footerOptions">

                        <Grid container  >
                            <Grid xs={12} sm={6} md={4}>

                                <div className="footerContactOptions">
                                    <div className="footerOptionsHeading text-left">
                                        Corporate Office Details
                                    </div>
                                    <div className="footerContactOptionsItem">
                                        <img src={phoneIcon} alt="phone" />
                                        <div className="contactData"><a target="_blank" rel="noreferrer" href="tel:+91-9054-30-5355">+91 9054-30-5355</a></div>
                                    </div>
                                    <div className="footerContactOptionsItem">
                                        <img src={emailIcon} alt="email" />
                                        <div className="contactData"><a target="_blank" rel="noreferrer" href="https://mail.google.com/mail/?view=cm&fs=1&to=info@portlinksindia.com">info@portlinksindia.com</a></div>
                                    </div>
                                    <div className="footerContactOptionsItem">
                                        <img src={locationIcon} alt="location" />
                                        <div className="contactData"><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/iRFpnkrSTwmrxjux6">Gala Empire, Drive-In Road, Ahmedabad, Gujarat 380052</a></div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid container xs={12} sm={6} md={8}>

                                <Grid xs={6} sm={6} md={6}>
                                    <div className="footerPagesOptions">
                                        <div className="footerOptionsHeading">
                                            Pages
                                        </div>
                                        <div className="footerPagesLinks">
                                            <Link to="/features">Features</Link>
                                            <Link to="/commodities/coal">Commodities</Link>
                                            <Link to="/blog">Blog</Link>
                                            <Link to="/pricing">Pricing</Link>

                                        </div>
                                    </div>
                                </Grid>
                                <Grid xs={6} sm={6} md={6}>
                                    <div className="footerImportantOptions">
                                        <div className="footerOptionsHeading">
                                            Important
                                        </div>
                                        <div className="footerImportantLinks">
                                            <Link to="/about">About Us</Link>
                                            <Link to="/career">Investor Relation</Link>


                                        </div>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={12}>
                                    <div className="footerPagesOptions">
                                        <div className="footerOptionsHeading">
                                            Support
                                        </div>
                                        <div className="footerPagesLinks footer-support">
                                            <Link to="/termsNcondition">Terms & Conditions</Link>
                                            <Link to="/privacyPolicy">Privacy Policy</Link>

                                        </div>
                                    </div>

                                </Grid>
                            </Grid>
                        </Grid>

                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={3}>
                    <div className="inquiry-form-area">
                        <div className="inquiry-form">
                            <div className="inquiry-title">
                                Inquire Now !
                            </div>
                            <div className="form-control">
                                <input type="text" placeholder='Full Name' value={fullName} onChange={(event) => setfullName(event.target.value)} />
                            </div>
                            <div className="form-control">
                                <input type="text" placeholder='Email Address' value={newsLetterEmail} onChange={(event) => setNewsLetterEmail(event.target.value)} />
                            </div>
                            <div className="form-control">
                                <input type="number" placeholder='Phone Number' value={phoneNumber} onChange={(event) => setphoneNumber(event.target.value)} />
                            </div>
                            <div className="form-control">
                                <textarea type="text"placeholder='Descriptions...' value={descriptions} onChange={(event) => setdescriptions(event.target.value)}>Descriptions</textarea>
                            </div>
                            <div className="submit-form">
                                Let’s Connect <span className="icon" onClick={emailSubscribe}> <ArrowForwardIcon /></span>
                            </div>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <div className="office-address">
                <div className="office-address-title">
                    Office Address
                </div>
                <hr />
                <div className="office-address-area">
                    <div className="footerOptions">
                        <Grid container  >
                            <Grid xs={12} sm={6} md={3}>
                                <div className="footerContactOptions">
                                    <div className="footerContactOptionsItem">
                                        <img src={locationIcon} alt="location" />
                                        <div className="contactData"><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/SM8Fs1x7MQocf25L7">Ground Floor, Solitaire Business Park, Gandhidham, Kutch, Gujarat - 370201.</a></div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={6} md={3}>
                                <div className="footerContactOptions">
                                    <div className="footerContactOptionsItem">
                                        <img src={locationIcon} alt="location" />
                                        <div className="contactData"><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/iRFpnkrSTwmrxjux6">Pipdiya Cross Road, Pipaliya-Jamnagar Road, Morbi, Gujarat -363660.</a></div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={6} md={3}>
                                <div className="footerContactOptions">
                                    <div className="footerContactOptionsItem">
                                        <img src={locationIcon} alt="location" />
                                        <div className="contactData"><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/RAktWPnScsRnbunP8">Office No : 12,
                                            Gayatri complex,
                                            Adani port road,
                                            Mundra, Kutch, Gujarat - 370421.</a></div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={6} md={3}>
                                <div className="footerContactOptions">
                                    <div className="footerContactOptionsItem">
                                        <img src={locationIcon} alt="location" />
                                        <div className="contactData"><a target="_blank" rel="noreferrer" href="https://goo.gl/maps/iRFpnkrSTwmrxjux6">83, Parsava City, Near Police Station, Bhachau, Kutch, Gujarat, India 370140.</a></div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="address-arrow">
                        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.207423 13.1954L0.207422 1.88464C0.207423 1.53118 0.347878 1.19222 0.597806 0.942294C0.847734 0.692366 1.18669 0.551911 1.54016 0.55191C1.89378 0.551753 2.23274 0.692209 2.48266 0.942137L8.13806 6.59753C8.388 6.84748 8.5286 7.18658 8.5286 7.54004C8.5286 7.8935 8.38802 8.23258 8.13806 8.48255L2.48266 14.1379C2.23272 14.3879 1.89378 14.5283 1.54016 14.5282C1.18669 14.5282 0.847734 14.3877 0.597806 14.1378C0.347878 13.8879 0.207423 13.5489 0.207423 13.1954L0.207423 13.1954Z" fill="white" />
                        </svg>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer

