import React from 'react'
import { Link } from 'react-router-dom';
import BenefitComp from './BenefitComp';
import "./Benefits.css";

const Benefits = () => {

    const benefitData = [
        {
            head:"0% Commision Portal",
            content:"Access all market-related information and quickly know the high-demand commodities and when they will be available along with all the product’s updated rates without investing precious time and effort.",
            image:"zeroCommission.svg"
        },
        {
            head:"Data Security & Privacy",
            content: <div> PORT LINKS INDIA is an end-to-end encrypted platform that uses cutting-edge technology to provide the highest levels of data protection. Clients data is not accessible to us. You can rest certain that your information is secure with us. To all of our clients, we have the right to ensure data security and privacy. Check out our <Link to='/privacyPolicy'> privacy policy </Link> for more information. </div>,
            image:"dataSecurity.svg"
        },
        {
            head:"Do more in less time",
            content:"Leverage a fully automated supply chain - from quickly finding available products, getting real-time updates of loading/unloading to booking logistics for transport, without having to initiate a never-ending chat thread.",
            image:"multiTasking3.svg"
        },
        {
            head:"Grow your Network",
            content:"Widen your market exposure from a limited range of areas to regions across the nation. Grow your connections and trade from any where to any place with ease.",
            image:"growNetwork.svg"
        },
    ]

    var row = false;

    return (
        <div className="benefitsContainer">
            {
                benefitData.map((benefit,index)=>{
                    row = !row;
                    return <BenefitComp key={index} benefit={benefit} row={row} />
                })
            }
        </div>
    )
}

export default Benefits
