import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./NewsOver.css";
// import news1 from '../../images/newsImages/news1.jpg'

const NewsOver = ({newsData}) => {
    const [formatedDate, setFormatedDate] = useState("");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
                        "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    
    const [formatTitle, setFormatTitle] = useState('');

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        var newDate = day+" "+monthNames[month-1]+", "+year;

        return newDate;
    }

    useEffect(() => {
        setFormatedDate(formatDate(newsData.date));
        setFormatTitle(newsData.title.length>40?(newsData.title.substring(0,40)+" ..."):newsData.title);
    },[newsData])


    return (
        <div className="blogOverContainer">
            <Link to={"/news/"+newsData._id}>
            <img src={process.env.REACT_APP_API_BASE_URL+"/newsImages/"+newsData.thumbnail} alt={newsData.thumbnailName} />
                <div className="blogDetails">
                    <div className="blogCommodity">{newsData?.category}</div>
                    <div className="blogTitle">{formatTitle}</div>
                    <div className="blogDate">{formatedDate}</div>
                </div>
            </Link>
        </div>
    )
}

export default NewsOver;
