import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";

import HomeScreen from './screens/Home/HomeScreen';
import FeaturesScreen from './screens/Features/FeaturesScreen';
import PricingScreen from './screens/Pricing/PricingScreen';
import CommodityScreen from './screens/Commodities/CommodityScreen';
import Navbar from './component/common/Navbar/Navbar';
import Footer from './component/common/Footer/Footer';
import AllBlogScreen from "./screens/Blogs/AllBlogScreen";
import ScrollToTop from './component/common/ScrollToTop';
import ShowBlogScreen from './screens/Blogs/ShowBlogScreen';
import AllCareerScreen from './screens/Career/AllCareerScreen';
import ShowCareerScreen from './screens/Career/ShowCareerScreen';
import AboutScreen from './screens/About/AboutScreen';
import PrivacyPolicyScreen from './screens/Important/PrivacyPolicyScreen';
import TNCScreen from './screens/Important/TNCScreen';
import NotFound from './screens/NotFound/NotFound';
import ServerError from './screens/ServerError/ServerError';
import PortDesc from './component/port/PortDesc';
import ProductScreen from './screens/Product/ProductScreen';
import ServiceScreen from './screens/Services/ServiceScreen';
import ProductDetails from './screens/ProductDetails/ProductDetailsScreen';
import ServiceDetails from './screens/ServiceDetails/ServiceDetailsScreen';
import AllNewsScreen from './screens/News/AllNewsScreen';
import ShowNewsScreen from './screens/News/ShowNewsScreen';
import ImportExportScreen from './screens/ImportExport/ImportExportScreen';
import Weather from './screens/Weather/Weather';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route path="/port/:portId" component={PortDesc} />
          <Route path="/features" component={FeaturesScreen} />
          <Route path="/pricing" component={PricingScreen} />
          <Route exact path="/blog" component={AllBlogScreen} />
          <Route path="/blog/:id" component={ShowBlogScreen} />
          <Route path="/about" component={AboutScreen} />
          <Route path="/commodities/:commodity" component={CommodityScreen} />
          <Route exact path="/career" component={AllCareerScreen} />
          <Route path="/career/:id" component={ShowCareerScreen} />
          <Route path="/privacyPolicy" component={PrivacyPolicyScreen} />
          <Route path="/termsNcondition" component={TNCScreen} />
          <Route path="/serverError" component={ServerError} />
          <Route path="/product" component={ProductScreen} />
          <Route path="/service" component={ServiceScreen} />
          <Route path="/product-details/:id" component={ProductDetails} />
          <Route path="/service-details/:id" component={ServiceDetails} />
          <Route exact path="/news" component={AllNewsScreen} />
          <Route path="/news/:id" component={ShowNewsScreen} />
          <Route path="/import-export" component={ImportExportScreen} />
          <Route path="/weather" component={Weather} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
