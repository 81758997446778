export const featuresOptionsData = ["Access the comodity","Trade comodity Buy/sale","Manage Orders & Stock","Real-time rate updates","Book & Track logistics",
                            "Location Management","Accounting Automation","Genrate Reports","Tracking Management"];

export const featuresContentData = [
    {
        image:"accessVessel.png",
        content:"Get away with the problems of non-existent details about the status of the available comodity stock and an appropriate contact person at the port. Quickly get real-time data of the whereabouts of the vessel’s contact person, loading/unloading points on the portal, and save time.",
    },
    {
        image:"tradeCommodity.png",
        content:"Look for the right material and quality, check for the updated price & product availability and get your logistics ready all through a single portal.",
    },
    {
        image:"manageOrder.png",
        content:"With automatic stock management, the portal shows the true data always. This helps you make better and precise decisions about buying/selling on the go.",
    },
    {
        image:"realTimeUpdates.png",
        content:"Get customized notifications from the real-time changes in the product rates to keep track of the prices of things you are most interested in.",
    },
    {
        image:"b&tLogistics.png",
        content:"Book and track  live status of your transport vehicle through the portal, Least efforts and energy from you to ensure business with ease.",
    },
    {
        image:"locationManage.png",
        content:"List down your operation fields and let us do the rest including loading, unloading and prior arrangements.",
    },
    {
        image:"accountingAutomation.png",
        content:"Accvoice is the cloud base accounting software having different features to provide innovative solution in Accounting Field. The Import of data facility is also available from different types of software available in the market.",
    },
    {
        image:"generateReports2.png",
        content:"Learn your states on the go with ready analytical reports which allows confidence to book, buy and sale at right pace.",
    },
    {
        image: "trackingManagement.png",
        content: <> Simplify management of your Transportation and Logistics businessFull-stack fleet management platform for all your operations, safety, and compliance needs. <div style={{marginBottom:"15px"}} /> Manage your trips, operations and fleet. Anytime, anywhere Leverage the power of our unified platform to run your business efficiently.</>
    }
]