import React from 'react';
import "./SocialMedia.css";

import linkedIn from "../../images/svgs/icons/blueLinkedIn.svg";
import faceBook from "../../images/svgs/icons/blueFaceBook.svg";
import twitter from "../../images/svgs/icons/blueTwitter.svg";
import whatsapp from "../../images/svgs/icons/whatsapp.svg";

const SocialMedia = ({id}) => {

    const SERVER_BASE = "https://www.portlinksindia.com/blog/";

    const linkedInLink = "https://www.linkedin.com/shareArticle?mini=true&url="+SERVER_BASE+id+"&title=&summary=I%20came%20across%20this%20amazing%20blog%20check%20this%20out.";
    const faceBookLink = "https://www.facebook.com/sharer/sharer.php?u="+SERVER_BASE+id;
    const twitterLink = "https://twitter.com/intent/tweet?url="+SERVER_BASE+id+"&text=I%20came%20across%20this%20amazing%20blog%20check%20this%20out.";
    const whatsappLink = "https://api.whatsapp.com/send?text="+SERVER_BASE+id;

    return (
        <div className="socialMediaContainer">
            <div className="shareHeading">Share On Social Media</div>
            <div className="socialMediaIcons">
                <a target="_blank" rel="noreferrer" href={linkedInLink}>
                    <div className="icon"><img src={linkedIn} alt="LinkedIn" /></div>
                </a>
                <a target="_blank" rel="noreferrer" href={faceBookLink}>
                    <div className="icon"><img src={faceBook} alt="Facebook" /></div>
                </a>
                <a target="_blank" rel="noreferrer" href={twitterLink}>
                    <div className="icon"><img src={twitter} alt="Twitter" /></div>
                </a>
                <a target="_blank" rel="noreferrer" href={whatsappLink}>
                    <div className="icon"><img src={whatsapp} alt="Whatsapp" /></div>
                </a>
            </div>
        </div>
    )
}

export default SocialMedia
