import React from 'react';
import "./keypoints.css";
import Container from '@mui/material/Container';

const KeyPoints = () => {



   return (
      <div className="KeyPointsContainer">
         <Container>
            <div className="feature-section-head">
               Who do we <span>help?</span>
               Key<span> Points</span> That We<span> Offers </span>
               <p>Our suite of on-demand services is catered to help trade with ease</p>
            </div>
            <div className="KeyPointsMain">
               <div className='keypoint-box keypointsTags1'>
                  <img src={require("../../images/svgs/keypoint1.svg").default} alt="" />
                  <div className="keypoint-title">
                     0% commission
                  </div>
               </div>
               <div className='keypoint-box keypointsTags2'>
                  <img src={require("../../images/svgs/keypoint2.svg").default} alt="" />
                  <div className="keypoint-title">
                     Automation
                  </div>
               </div>
               <div className='keypoint-box keypointsTags3'>
                  <img src={require("../../images/svgs/keypoint3.svg").default} alt="" />
                  <div className="keypoint-title">
                     Standalone And Centralised System
                  </div>
               </div>
               <div className='keypoint-box keypointsTags4'>
                  <img src={require("../../images/svgs/keypoint4.svg").default} alt="" />
                  <div className="keypoint-title">
                     Verified Users
                  </div>
               </div>
               <div className='keypoint-box keypointsTags5'>
                  <img src={require("../../images/svgs/keypoint5.svg").default} alt="" />
                  <div className="keypoint-title">
                     AI/ML technology
                  </div>
               </div>
               <div className='keypoint-box keypointsTags6'>
                  <img src={require("../../images/svgs/keypoint6.svg").default} alt="" />
                  <div className="keypoint-title">
                     Fully Customizable
                  </div>
               </div>
            </div>
         </Container>
      </div>
   )
}

export default KeyPoints
