import axios from "axios";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import "./AllCareer.css";
import CareerCard from "./CareerCard";

import noJobs from "../../images/svgs/noJobs.svg";
import { useHistory } from "react-router";

const AllCareer = () => {

    const [showProgress, setShowProgress] = useState(true);
    const [allJobs, setAllJobs] = useState([]);

    const history = useHistory();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL+"/api/allJobs")
        .then((response)=>{
            if(response.status===200){
                setAllJobs(response.data.jobData);
                setShowProgress(false);
            }
            else{
                console.log("Error"+response)
            }
        })
        .catch(err=>{
            console.log(err);
        })
    },[])

    return (
        <div className="allCareerContainer">
            <div className="allCareerHead">Open Roles</div>
            <div className="allCareerSubHead">We are actively hiring accross the globe.</div>
            <div className="allCareers">
                <CircularProgress  sx={{color:"#415AA9",display:(showProgress?"inherit":"none"),margin:"0 auto"}} />
                {
                    allJobs.length===0
                    ? showProgress
                        ? <div></div>
                        : <div className="noJobFound" >
                            <img src={noJobs} alt="No Jobs" />
                            <div className="noJobFoundMsg" >Oops!! Looks like no jobs are added yet</div>
                            <div className="bacKToHomeBtn" onClick={()=>history.push("/")}>Back To Home</div>
                        </div>
                    :
                    allJobs.map((jobData)=>{
                        return <CareerCard jobData={jobData} />
                    })
                }
            </div>
        </div>
    )
}

export default AllCareer
