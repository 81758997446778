import React from 'react'
import BlogNavbar from '../../component/blog/BlogNavbar/BlogNavbar'
import ProductList from '../../component/product/ProductListing/ProductListing'

const ProductScreen = () => {

    
    return (
        <div className='products'>
          <BlogNavbar />
          <ProductList/>
        </div>
    )
}

export default ProductScreen
