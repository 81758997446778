import React from 'react'
import ShowCareer from '../../component/career/ShowCareer/ShowCareer'

const ShowCareerScreen = () => {
    return (
        <>
            <ShowCareer />
        </>
    )
}

export default ShowCareerScreen
