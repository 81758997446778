import React from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import "./assets/GetQuot.css";
import "./assets/LightBlueBgBanner.css";
import "./assets/ServiceAbout.css";
import "./assets/ServiceInfo.css";
import "./assets/ServiceKeyPoints.css";
import "./assets/ServiceSuggestion.css";
import "./assets/ServiceWork.css";
import { useEffect, useState, props } from 'react'
import axios from 'axios';
import { useHistory, useParams } from 'react-router';
import productwork from "./assets/productwork1.svg";
import productMainImg from "./assets/product1.svg";
import CommonConfig from '../../Utils/CommonConfig'
import { Link, useLocation } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ServiceDetails = () => {
    const [serviceTypeList, SetServiceTypeList] = useState([]);
    const [serviceList, SetServiceList] = useState({});
    const [selectedProductIdx, SetSelectedProductIdx] = useState(0);
    const [formatTitle, setFormatTitle] = useState("");
    const [showProgress, setShowProgress] = useState(false);
    const [getLatestServiceList, SetLatestServiceList] = useState([]);

    const param = useParams();
    const initialLoad = 9;
    const laterLoad = 3;
    const history = useHistory();
    useEffect(() => {
        getServiceByID(param.id);
        LatestServiceList(param.id);
        allServiceType(param.id);
    }, []);
    const getServiceByID = (ID) => {
        // console.log('ID ', ID)
        axios.post(process.env.REACT_APP_API_BASE_URL + "/api/getServicesByID", { ID: ID })
            .then((response) => {
                SetServiceList(response.data.servicesData);


            })
            .catch(err => {
                // history.push({ pathname: '/serverError', state: { code: err.response.status, msg: err.response.data.err.message } });
            });
    }
    const LatestServiceList = (ID) => {
        axios.post(process.env.REACT_APP_API_BASE_URL + "/api/LatestServiceList", { ID: ID })
            .then((response) => {

                if (response?.data?.servicesData?.length) {
                    SetLatestServiceList(response.data.servicesData);

                }

            })
            .catch(err => {
                console.log(err)
            });
    }
    const allServiceType = (ID) => {
        axios.post(process.env.REACT_APP_API_BASE_URL + "/api/allServiceType", { ID: ID })
            .then((response) => {
                // console.log("jkgjgjgjg",response?.data?.serviceData.length)
                if (response?.data?.serviceData.length) {
                    SetServiceTypeList(response?.data?.serviceData);
                    setShowProgress(true);
                    // console.log('ABHIKOUT')
                } else {
                    // console.log('ABHIKIN')
                    setShowProgress(false);
                }

            })
            .catch(err => {
                console.log(err)
            });
    }
    return (
        <>

            <div className="lightBlueBgBannerContainer">
                <div className="lightBlueBgBannerHeadline">{serviceList.servicesName}
                </div>
            </div>
            <div className="product-about-container">
                <Container>
                    <div>
                        <Grid container  >
                            <Grid xs={12} md={6}>
                                <div className="product-main-img">
                                    <img src={process.env.REACT_APP_API_BASE_URL + "/servicesImages/" + serviceList.servicesImage} alt={serviceList.servicesImage}

                                    />

                                </div>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <div className="product-main-info">
                                    {serviceList.servicesHeadingName}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='mt-5'>
                        <div className="main-header">
                            About Service
                        </div>
                        <p className="product-about">
                            {serviceList.servicesDescription}
                        </p>
                    </div>
                    {showProgress ? <>
                        <div className='product-details'>
                            <div className="main-header">
                                {serviceList.servicesName} Service
                            </div>

                            <div className="about-product-tags-area">
                                {!serviceTypeList.length ? null : Object.keys(serviceTypeList).map((x) => {
                                    return (
                                        <div className={Number(x) == selectedProductIdx ? "about-product-tag active" : "about-product-tag inactive"} key={x} onClick={() => SetSelectedProductIdx(x)}>
                                            {serviceTypeList[x].serviceTypeHeadingName}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="product-info-container">
                                <Container>
                                    <div className="main-header">
                                        How it ‘s work
                                    </div>
                                    <p className="product-works-detail" dangerouslySetInnerHTML={{ __html: serviceTypeList[selectedProductIdx]?.serviceTypeContent }}>
                                    </p>

                                </Container>
                            </div>
                            <div className='service-key-pionts-container'>
                                <Container>

                                    <div className="main-header">
                                        Key Points

                                    </div>

                                    <ul>
                                        {serviceTypeList[selectedProductIdx]?.serviceTypeKeyPoint.split(',').map(x => {
                                            return (
                                                <li>
                                                    {x}
                                                </li>
                                            )
                                        })}
                                    </ul>


                                </Container>
                            </div>
                        </div>
                        <div className="get-quot-section">
                            <h2>Get Quick Quotation </h2>
                            <p>Variations of passages of Lorem Ipsum available</p>
                            <div className='quot-btn'><a href={process.env.REACT_APP_API_BASE_URL + "/servicesImages/" + serviceTypeList[selectedProductIdx]?.serviceTypeQuation} target='_blank' download >   <div>Get me a quote  </div> <div><ArrowForwardIcon /></div></a>

                            </div>
                        </div>
                    </> : null}
                    <div className="product-more-container">
                        <Container className='p-0'>
                            <div className="main-header">
                                More Services
                            </div>

                            <Grid container>
                                {getLatestServiceList.map((data, Idx) => {
                                    return (
                                        <Grid xs={12} sm={6} md={4} onClick={() => {
                                            setShowProgress(false);
                                            getServiceByID(data._id);
                                            LatestServiceList(data._id);
                                            allServiceType(data._id);
                                        }}>
                                            <Link
                                                to={{
                                                    pathname: `/service-details/${data._id}`,
                                                    state: { data },
                                                }}
                                            >
                                                <div className="px-2">
                                                    <div className="more-product-card">
                                                        <div className="product-img">
                                                            <img src={process.env.REACT_APP_API_BASE_URL + "/servicesImages/" + data.servicesImage} alt={data.servicesImage} />
                                                        </div>
                                                        <div className="product-title">
                                                            <div>
                                                                <h3>{data.servicesName}</h3>

                                                            </div>
                                                            <div> <p>
                                                                {data.servicesDescription.length > 70 ? (data.servicesDescription.substring(0, 70) + " ...") : data.servicesDescription}
                                                            </p>

                                                            </div>
                                                        </div>
                                                    </div></div></Link>
                                        </Grid>
                                    )
                                })}
                                {/* <Grid xs={12} md={6}>
                    </Grid> */}
                            </Grid>
                        </Container>
                    </div>
                </Container>
            </div>




        </>)
}

export default ServiceDetails