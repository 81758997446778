import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./LandingHeroComp.css";

import landingHeroImage from "../../images/svgs/landingHeroImage5.svg";
import headervideo from "./videos/port_.mp4";
import Dialog from '@mui/material/Dialog';
import advertiseSVG from "../../images/svgs/advertise.svg";
import CommonConfig from "../../../Utils/CommonConfig";
import axios from 'axios';
import cogoToast from "cogo-toast";
const LandingHeroComp = () => {
    const [openAdvertise, setOpenAdvertise] = useState(false);

    const [fullName, setFullName] = useState('');
    const [fullNameErr, setFullNameErr] = useState(false);
    const [fullNameErrText, setFullNameErrText] = useState(false);

    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [emailErrText, setEmailErrText] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberErr, setPhoneNumberErr] = useState(false);
    const [phoneNumberErrText, setPhoneNumberErrText] = useState(false);

    const [descriptions, setDescriptions] = useState('');
    const [descriptionsErr, setDescriptionsErr] = useState(false);
    const [descriptionsErrText, setDescriptionsErrText] = useState(false);

    const inquire = () => {
        setFullName('');
        setFullNameErr(false);
        setFullNameErrText('');

        setEmail('');
        setEmailErr(false);
        setEmailErrText('');

        setPhoneNumber('');
        setPhoneNumberErr(false);
        setPhoneNumberErrText('');

        setDescriptions('');
        setDescriptionsErr(false);
        setDescriptionsErrText('');
        document.getElementById("inquireForm").classList.toggle("inquire-active");

    }
    const handleChange = (e, type) => {
        if (type == 'fullName') {
            if (CommonConfig.isEmpty(e.target.value)) {
                setFullName('');
                setFullNameErr(true);
                setFullNameErrText('Full name is required');
            }
            else {
                setFullName(e.target.value);
                setFullNameErr(false);
                setFullNameErrText('');
            }
        }
        else if (type == 'email') {
            if (CommonConfig.isEmpty(e.target.value)) {
                setEmail('');
                setEmailErr(true);
                setEmailErrText('Email is required');
            }
            else {
                setEmail(e.target.value);
                setEmailErr(false);
                setEmailErrText('');
            }
        }
        else if (type == 'phoneNumber') {
            if (CommonConfig.isEmpty(e.target.value)) {
                setPhoneNumber('');
                setPhoneNumberErr(true);
                setPhoneNumberErrText('Phone number is required');
            }
            else {
                setPhoneNumber(e.target.value);
                setPhoneNumberErr(false);
                setPhoneNumberErrText('');
            }
        }
        else if (type == 'description') {
            if (CommonConfig.isEmpty(e.target.value)) {
                setDescriptions('');
                setDescriptionsErr(true);
                setDescriptionsErrText('Description is required');
            }
            else {
                setDescriptions(e.target.value);
                setDescriptionsErr(false);
                setDescriptionsErrText('');
            }
        }
    }
    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(fullName)) {
            formIsValid = false;
            setFullNameErr(true);
            setFullNameErrText('Full name is required');
        }
        else {
            setFullNameErr(false);
            setFullNameErrText('');
        }

        if (CommonConfig.isEmpty(email)) {
            formIsValid = false;
            setEmailErr(true);
            setEmailErrText('Email is required');
        }
        else if (!email.match(CommonConfig.RegExp.email)) {
            formIsValid = false;
            setEmailErr(true);
            setEmailErrText('Email is not valid');
        }
        else {
            setEmailErr(false);
            setEmailErrText('');
        }

        if (CommonConfig.isEmpty(phoneNumber)) {
            formIsValid = false;
            setPhoneNumberErr(true);
            setPhoneNumberErrText('Phone number is required');
        }
        else {
            setPhoneNumberErr(false);
            setPhoneNumberErrText('');
        }

        if (CommonConfig.isEmpty(descriptions)) {
            formIsValid = false;
            setDescriptionsErr(true);
            setDescriptionsErrText('Description is required');
        }
        else {
            setDescriptionsErr(false);
            setDescriptionsErrText('');
        }
        return formIsValid

    }
    const handleSubmit = (e) => {
        try {
            if (validate()) {
                let data = {
                    FullName: fullName,
                    Email: email,
                    Description: descriptions,
                    PhoneNumber: phoneNumber
                };

                axios.post(process.env.REACT_APP_API_BASE_URL + "/api/addEnquire/", data)
                    .then((response) => {
                        if (response) {
                            inquire();
                            cogoToast.success("Enquiry sent successfully");
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        } catch (e) { console.log(e); }
    }
    return (
        <>
            <div className="LandingHeroCompContainer">
                <video muted loop autoPlay={true} className='header-video'>
                    <source src={headervideo} type="video/mp4" />
                </video>
                <div className='head-line'>
                    <div className="LandingHeroCompHead">The future of Maritime & Supply Chain with automation</div>
                    <div className="LandingHeroCompSubHead">See how Port Links India facilitates the ease of business with customisable solutions, real-time updates, enabling online booking of logistics and quicker communication between stakeholders with data security and data privacy.</div>
                    <div className="LandingHeroCompBtns">
                        <a target="_blank" rel="noreferrer" href="https://login.portlinksindia.com/">
                            <div className="LandingHeroCompGetStarted">Get Started</div>
                        </a>
                        <Link to="/features">
                            <div className="LandingHeroCompLearnMore">Learn More</div>
                        </Link>
                    </div>
                </div>
                <div id='inquireForm' className="inquire-now">
                    <div className="d-flex align-items-center">

                        <div className="inquire-btn" onClick={inquire} >
                            Inquiry Now
                        </div>
                        <div className="inquire-form">
                            <div className="inquiry-form-area p-0">
                                <div className="inquiry-form">
                                    <div className="form-control">
                                        <input type="text" value={fullName} onChange={(e) => handleChange(e, 'fullName')} placeholder='Full Name' />
                                        {fullNameErr ? (
                                            <small id="username2-help" style={{ color: 'red' }}>
                                                {fullNameErrText}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <input type="text" value={email} onChange={(e) => handleChange(e, 'email')} placeholder='Email Address' />
                                        {emailErr ? (
                                            <small id="username2-help" style={{ color: 'red' }}>
                                                {emailErrText}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <input type="number" value={phoneNumber} onChange={(e) => handleChange(e, 'phoneNumber')} placeholder='Phone Number' />
                                        {phoneNumberErr ? (
                                            <small id="username2-help" style={{ color: 'red' }}>
                                                {phoneNumberErrText}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <textarea type="text" value={descriptions} onChange={(e) => handleChange(e, 'description')} placeholder='Descriptions...' >Descriptions</textarea>
                                        {descriptionsErr ? (
                                            <small id="username2-help" style={{ color: 'red' }}>
                                                {descriptionsErrText}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="submit-form" onClick={(e) => handleSubmit(e)}>
                                        Inquire Now
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ladingpage-img'>

                <img src={landingHeroImage} alt="Great Network" />
            </div>
            <Dialog onClose={() => setOpenAdvertise(false)} open={openAdvertise}>
                <div className="advertise-box">
                    <div className="advertise-img">
                        <img src={advertiseSVG} alt="Image not found" />
                    </div>
                    {/* <div className="icon-close">
                        X
                    </div> */}
                </div>
            </Dialog>
        </>
    )
}

export default LandingHeroComp
