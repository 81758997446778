import React from 'react'
import Benefits from '../../component/home/Benefits/Benefits'
import FAQs from '../../component/common/FAQs/FAQs'
// import Testimonial from '../../component/home/Testimonial/Testimonial'
import Recognition from '../../component/home/Recognition/Recognition'
import Stats from '../../component/home/Stats/Stats'
import LatestBlog from '../../component/common/LatestBlog/LatestBlog'
import GetStarted from '../../component/common/GetStarted/GetStarted'
import LandingHeroComp from '../../component/home/LandingHeroComp/LandingHeroComp'
import PortsSVG from '../../component/home/PortsMap/PortsSVG'
import OurClients from '../../component/home/OurClients/OurClients'

const HomeScreen = () => {

    const getStartedData = {
        title:"Get Started with our Portal",
        subTitle:"Signup on our portal and increase your business",
        btn:"Join Now",
        link: "https://login.portlinksindia.com/"
    }
    
    return (
        <>
            <LandingHeroComp />
            <Recognition />
            {/* <OurClients /> */}
            {/* <Stats /> */}
            <PortsSVG />
            <Benefits />
            {/* <Testimonial /> */}
            {/* <LatestBlog /> */}
            <FAQs />
            <GetStarted getStartedData={getStartedData} />
        </>
    )
}

export default HomeScreen
