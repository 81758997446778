import React, { useState } from 'react';
import "./NewsLetterBar.css";
import axios from "axios";
import validator from 'validator';

const NewsLetterBar = () => {

    const [email, setEmail] = useState("");
    const [emailResponse, setEmailResponse] = useState("");
    const [emailResMsg, setEmailResMsg] = useState("");

    const emailSubscribe = () =>{
        if(validator.isEmail(email)){
            const emailData = {email};
            axios.post(process.env.REACT_APP_API_BASE_URL+"/api/subscribeEmail",emailData)
            .then(response => {
                if(response.status===200){
                    setEmailResponse("success");
                    setEmailResMsg("You will be updated with our latest content");
                    setEmail("");

                    setTimeout(()=>{
                        setEmailResponse("");
                        setEmailResMsg("");        
                    },3000);

                } else {
                    setEmailResMsg("Some Error Occured !!");
                    setEmailResponse("error");
                    
                    setTimeout(()=>{
                        setEmailResponse("");
                        setEmailResMsg("");        
                    },3000);
                }
            })
            .catch(err => {
                setEmailResponse("error");
                setEmailResMsg("Given Email is already present");

                setTimeout(()=>{    
                    setEmailResponse("");
                    setEmailResMsg("");        
                },3000);
            })

        }
        else {
            setEmailResponse("error");
            setEmailResMsg("Please Enter Valid Email");

            setTimeout(()=>{    
                setEmailResponse("");
                setEmailResMsg("");        
            },3000);
        }
    }

    const emailResponseStyle = {
        textAlign:"left",
        display: (emailResponse===""?"none":"inherit"),
        color: (emailResponse==="success"?"#F1F2F7":"#EB5757"),
        transition: "all 0.2s",
        marginTop: "5px",
        fontSize:"12px",
        lineHeight:"20px"
    }


    return (
        <div className="newsLetterContainer">
            <div className="newsLetterTitle">Get Regular Updates From Us</div>
            <div className="newsLetterSubTitle">Subscribe to our newsletter to get latest news and updates</div>
            <div style={{width:"fit-content",margin:"auto"}}>
                <div className="newsLetterForm" style={{paddingBottom:(emailResponse===""?"25px":"0")}} >
                    <input type="email" placeholder="Enter your email Id here" value={email} onChange={(e)=>setEmail(e.target.value)} />
                    <div className="newsLetterBtn">
                        <button type="submit" onClick={()=>emailSubscribe()}>Subscribe</button>
                    </div>
                </div>
                <span style={emailResponseStyle}>{emailResMsg}</span>
            </div>
        </div>
    )
}

export default NewsLetterBar;