import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./BlogOver.css";

const BlogOver = ({blogData}) => {

    const [formatedDate, setFormatedDate] = useState("");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
                        "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    
    const [formatTitle, setFormatTitle] = useState("");


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        var newDate = day+" "+monthNames[month-1]+", "+year;

        return newDate;
    }

    useEffect(() => {
        setFormatedDate(formatDate(blogData.date));
        setFormatTitle(blogData.title.length>40?(blogData.title.substring(0,40)+" ..."):blogData.title);
    },[blogData])

    return (
        <div className="blogOverContainer">
            <Link to={"/blog/"+blogData._id}>
                <img src={process.env.REACT_APP_API_BASE_URL+"/blogImages/"+blogData.thumbnail} alt={blogData.thumbnailName} />
                <div className="blogDetails">
                    <div className="blogCommodity">{blogData.category}</div>
                    <div className="blogTitle">{formatTitle}</div>
                    <div className="blogDate">{formatedDate}</div>
                </div>
            </Link>
        </div>
    )
}

export default BlogOver;
